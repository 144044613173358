import { ICostCode, IProjectCostCode } from '../../types';

export const SET_COMPANY_COST_CODES = 'SET_COMPANY_COST_CODES'
export const CLEAR_COMPANY_COST_CODES = 'CLEAR_COMPANY_COST_CODES'

export const SET_PROJECT_COST_CODES = 'SET_PROJECT_COST_CODES'
export const CLEAR_PROJECT_COST_CODES = 'CLEAR_PROJECT_COST_CODES'

export const setProjectCostCodes = (codes: IProjectCostCode[]) => ({
  type: SET_PROJECT_COST_CODES,
  payload: codes,
});

export const clearProjectCostCodes = () => ({
  type: CLEAR_PROJECT_COST_CODES,
})

export const setCompanyCostCodes = (codes: ICostCode[]) => ({
  type: SET_COMPANY_COST_CODES,
  payload: codes,
});

export const clearCompanyCostCodes = () => ({
  type: CLEAR_COMPANY_COST_CODES,
})

import { makeStyles, createStyles } from '@material-ui/core/styles';

const fieldStyles = {
  fontSize: '13px',
  fontWeight: 400,
  color: '#1b242d',
};

export default makeStyles(() =>
  createStyles({
    header: {
      fontSize: '12px',
      fontWeight: 700,
      margin: '0px',
      color: '#8b9196',
    },
    box: {
      marginLeft: '10px',
    },
    value: {
      display: 'block',
      minHeight: '18px',
      ...fieldStyles,
    },
    content: {
      whiteSpace: 'normal',
    },
    childrenContainer: {
      display: 'block',
      minHeight: '18px',
      ...fieldStyles,
    },
  })
);

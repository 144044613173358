import React, { useMemo } from 'react';
import { Fieldset, SortableIconChips, SortableIconProps } from '@esub-engineering/react-component-library';

export interface PdfFileSelectorProps extends SortableIconProps {
  reportName: string;
}

/**
 * Sortable icon chips for files used with PDF/Email functionality
 * @todo replace other instances
 */
export const FileSelector = React.memo(({ reportName, ...rest }: PdfFileSelectorProps) => {
  const description = useMemo(
    () =>
      `Click to select. Files selected are appended to the end of the ${reportName}. Drag and drop to
    reorder.`,
    [reportName]
  );

  return (
    <Fieldset title="include files" description={description} divider={false}>
      <SortableIconChips {...rest} />
    </Fieldset>
  );
});

import { gql } from 'graphql-request';

export const versionSetKey = 'versionSets';
export const key = 'sheets';
export const searchKey = `${key}.search`;
export const searchVersionSetsKey = `${versionSetKey}.search`;
export const labelKey = `label.${key}.search`;

export const searchSheets = gql`
  query searchSheets(
    $direction: ModelSortDirection
    $filter: SheetSearchFilterInput
    $limit: Int
    $page: Int
    $sortBy: SheetSearchSortByColumn
    $term: String
  ) {
    searchSheets(
      direction: $direction
      filter: $filter
      limit: $limit
      page: $page
      sortBy: $sortBy
      term: $term
    ) {
      pagination {
        currentPage
        hits
        pageSize
        pages
      }
      items {
        highlight {
          name
        }
        document {
          versionSetsCount
          entity {
            created
            discipline
            id
            markup
            modified
            name
            imageMetadata {
              publicId
              cloudName
            }
            number
            pdfUrl
            project {
              id
              name
              number
            }
            tags
            thumbnailUrl
            versionSet {
              dateIssued
              name
              id
            }
          }
        }
      }
    }
  }
`;

export const searchVersionSetsFull = gql`
  query searchVersionSets(
    $direction: ModelSortDirection
    $filter: VersionSetSearchFilterInput
    $limit: Int
    $page: Int
    $sortBy: VersionSetSearchSortByColumn
    $term: String
  ) {
    searchVersionSets(
      direction: $direction
      filter: $filter
      limit: $limit
      page: $page
      sortBy: $sortBy
      term: $term
    ) {
      items {
        document {
          entity {
            created
            dateIssued
            id
            modified
            name
            project {
              id
              name
              number
            }
          }
        }
        highlight {
          name
        }
      }
      pagination {
        currentPage
        hits
        pageSize
        pages
      }
    }
  }
`;

export const searchSheetsFacets = gql`
  query listSheetsSearchFacets($input: ListSheetSearchFacetsInput!) {
    listSheetSearchFacets(input: $input) {
      disciplines {
        items {
          count
          key
        }
        pagination {
          currentPage
          hits
          pageSize
          pages
        }
      }
      tags {
        items {
          count
          key
        }
        pagination {
          currentPage
          hits
          pageSize
          pages
        }
      }
      versionSets {
        pagination {
          currentPage
          hits
          pageSize
          pages
        }
        items {
          versionSet {
            created
            dateIssued
            id
            modified
            name
            createdBy {
              id
            }
            project {
              id
              name
              number
            }
          }
          key
        }
      }
    }
  }
`;

export const listSheetDisciplinesSearchFacets = gql`
  query listSheetDisciplinesSearchFacets($limit: Int, $page: Int, $projectId: ID!) {
    listSheetDisciplinesSearchFacets(
      input: { limit: $limit, page: $page, projects: { id: { eq: $projectId } } }
    ) {
      items {
        count
        key
      }
      pagination {
        currentPage
        hits
        pageSize
        pages
      }
    }
  }
`;

export const listVersionSetsSearchFacets = gql`
  query listVersionSetsSearchFacets($limit: Int, $page: Int, $projectId: ID!) {
    listVersionSetsSearchFacets(
      input: { limit: $limit, page: $page, projects: { id: { eq: $projectId } } }
    ) {
      items {
        key
        versionSet {
          id
          name
        }
      }
      pagination {
        currentPage
        hits
        pageSize
        pages
      }
    }
  }
`;

export const listSheetTagsSearchFacets = gql`
  query listSheetTagsSearchFacets($limit: Int, $page: Int, $projectId: ID!) {
    listSheetTagsSearchFacets(
      input: { limit: $limit, page: $page, projects: { id: { eq: $projectId } } }
    ) {
      items {
        count
        key
      }
      pagination {
        currentPage
        hits
        pageSize
        pages
      }
    }
  }
`;

export const listTags = gql`
  query listTags {
    listTags {
      discipline {
        count
        key
        name
      }
    }
  }
`;

export const listDisciplines = gql`
  query listDisciplines {
    listDisciplines {
      discipline {
        count
        key
        name
      }
    }
  }
`;

export const getVersionSet = gql`
  query getVersionSet($id: String!, $projectId: String!) {
    getVersionSet(input: { id: $id, projectId: $projectId }) {
      dateIssued
      id
      name
      project {
        id
        name
        number
      }
    }
  }
`;

export const getVersionSetPdfJob = gql`
  query getVersionSetPdfJob($id: String!, $projectId: String!) {
    getVersionSetPdfJob(input: { id: $id, projectId: $projectId }) {
      created
      id
      modified
      status
      error
    }
  }
`;

// ASK: should this include a projectId?
export const listSheets = gql`
  query listSheets($versionSetId: String!) {
    listSheets(input: { versionSetId: $versionSetId }) {
      items {
        created
        discipline
        id
        modified
        name
        number
        pdfUrl
        markup
        tags
        thumbnailUrl
        uploadBatch
        imageMetadata {
          publicId
          cloudName
        }
        project {
          id
          name
          number
        }
        versionSet {
          id
          name
        }
      }
      cursor
    }
  }
`;

export const getSheetsOcrJob = gql`
  query getSheetsOcrJob($id: String!, $projectId: String!) {
    getSheetsOcrJob(input: { id: $id, projectId: $projectId }) {
      status
      created
      id
      modified
      nameBoundingBoxCoordinates {
        height
        width
        x
        y
      }
      numberBoundingBoxCoordinates {
        height
        width
        x
        y
      }
      createdBy {
        id
      }
    }
  }
`;

export const searchVersionSets = gql`
  query searchVersionSets($id: ID!) {
    searchVersionSets(filter: { projects: { id: { eq: $id } } }) {
      items {
        document {
          entity {
            dateIssued
            id
            name
          }
        }
      }
    }
  }
`;

export const getSheet = gql`
  query getSheet($id: String!, $projectId: String!) {
    getSheet(input: { id: $id, projectId: $projectId }) {
      created
      discipline
      id
      markup
      modified
      name
      number
      pdfUrl
      tags
      thumbnailUrl
      imageMetadata {
        publicId
        cloudName
      }
      versionSet {
        id
        dateIssued
        name
      }
    }
  }
`;

export const listSheetVersions = gql`
  query listSheetVersions($projectId: ID!, $number: String!, $page: Int, $limit: Int) {
    searchSheets(
      sortBy: LAST_UPDATED
      direction: DESC
      page: $page
      limit: $limit
      filter: {
        number: { eq: $number }
        projects: { id: { eq: $projectId } }
        showOnlyMostRecent: { eq: false }
      }
    ) {
      pagination {
        pages
        currentPage
        hits
        pageSize
      }
      items {
        document {
          entity {
            id
            number
            name
            discipline
            markup
            modified
            pdfUrl
            tags
            thumbnailUrl
            created
            project {
              companyId
              id
            }
            versionSet {
              name
              dateIssued
            }
          }
          versionSetsCount
        }
      }
    }
  }
`;

export const listVersionSets = gql`
  query listVersionSets($projectId: ID!, $name: String!) {
    searchVersionSets(filter: { name: { eq: $name }, projects: { id: { eq: $projectId } } }) {
      items {
        document {
          entity {
            name
            id
            project {
              companyId
              id
            }
          }
        }
      }
    }
  }
`;

export const listSheetsInVersionSet = gql`
  query listSheetVersions(
    $projectId: ID!
    $number: String!
    $versionSetId: ID!
    $page: Int
    $limit: Int
  ) {
    searchSheets(
      sortBy: LAST_UPDATED
      direction: DESC
      page: $page
      limit: $limit
      filter: {
        number: { eq: $number }
        projects: { id: { eq: $projectId } }
        versionSets: { id: { eq: $versionSetId } }
        showOnlyMostRecent: { eq: false }
      }
    ) {
      pagination {
        pages
        currentPage
        hits
        pageSize
      }
      items {
        document {
          entity {
            id
            number
            name
            discipline
            markup
            modified
            pdfUrl
            tags
            thumbnailUrl
            created
            project {
              companyId
              id
            }
            versionSet {
              name
              dateIssued
            }
          }
          versionSetsCount
        }
      }
    }
  }
`;

export const listSheetToRfiLinks = gql`
  query listSheetToRfiLinks($cursor: String, $limit: Int, $projectId: String!, $sheetId: String!) {
    listSheetToRfiLinks(
      input: { cursor: $cursor, limit: $limit, projectId: $projectId, sheetId: $sheetId }
    ) {
      cursor
      items {
        created
        id
        modified
        rfi {
          id
          status
          customNo
          subject
        }
        project {
          id
          name
          number
        }
        sheet {
          id
          name
          number
        }
        snapshot {
          id
          snapshotSheetName
          snapshotSheetNumber
        }
      }
    }
  }
`;

import React from 'react';
import { useTranslation } from 'react-i18next';

export const LanguageSelect = React.memo(() => {
  const { i18n } = useTranslation();

  const changeLanguage = (e: React.ChangeEvent<HTMLSelectElement>) => {
    i18n.changeLanguage(e.target.value);
  };

  return (
    <select onChange={changeLanguage}>
      <option value="en">English</option>
      <option value="es">Español</option>
    </select>
  );
});

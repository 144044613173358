import React from 'react';
import { DataGridFieldProps } from '../../types';
import { DataGridField } from '../DataGridField';

/**
 * DataGrid field for displaying row cells in columns
 */
export const DataGridFieldStacked = React.memo(({ fields }: { fields: DataGridFieldProps[] }) => (
  <>
    {fields.map(({ ...rest }, idx) => (
      // eslint-disable-next-line react/no-array-index-key
      <DataGridField {...rest} key={`${Date.now()}-${idx}`} />
    ))}
  </>
));

import { Text, ReactSortableItem, SortableIconChips, useCrudFlow } from '@esub-engineering/react-component-library';
import React, { useCallback } from 'react';
import { useSortableItem } from '../../hooks';

type SortableChipListProps = {
  initialList?: ReactSortableItem[];
  initialCheckAll?: boolean;
  onChange: (list: ReactSortableItem[]) => void;
  handleCheck?:
    | ((
        event: React.ChangeEvent<HTMLInputElement>,
        value: ReactSortableItem[],
        index?: number
      ) => void)
    | undefined;
  title?: string;
};

export const SortableChipList = ({
  initialList = [],
  initialCheckAll,
  handleCheck,
  onChange,
  title,
}: SortableChipListProps) => {
  const { list, setList, handleCheckAll } = useSortableItem(initialList);

  const { setDirty } = useCrudFlow();

  const wrappedCheckAll = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, value: ReactSortableItem[]) => {
      setDirty(true);
      if (handleCheckAll) handleCheckAll(event, value);
    },
    [handleCheckAll, setDirty]
  );

  const wrappedCheck = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, value: ReactSortableItem[]) => {
      setDirty(true);
      if (handleCheck) handleCheck(event, value);
    },
    [handleCheck, setDirty]
  );

  const wrappedSetList = useCallback(
    (currentList: ReactSortableItem[]) => {
      onChange(currentList);
      setList(currentList);
    },
    [onChange, setList]
  );

  return (
    <>
      {title && <Text weight="bold">{title}</Text>}
      <SortableIconChips
        list={list}
        setList={wrappedSetList}
        initialCheckAll={initialCheckAll}
        handleCheckAll={wrappedCheckAll}
        handleCheck={wrappedCheck}
      />
    </>
  );
};

type Environment = 'production' | 'demo' | 'dr' | 'qa' | 'stage' | 'dev' | 'migration';

export const getEnvironment = ({ skipNodeEnv = false }: { skipNodeEnv?: boolean }): Environment => {
  const { host } = window.location;

  const { NODE_ENV } = process.env;

  if (NODE_ENV !== 'production' && !skipNodeEnv) {
    return 'dev';
  }

  if (host === 'esubcloud.com' || host === 'www.esubcloud.com') {
    return 'production';
  }

  if (host === 'demo.esubcloud.com' || host === 'www.demo.esubcloud.com') {
    return 'demo';
  }

  if (host === 'dr.esubcloud.com' || host === 'www.dr.esubcloud.com') {
    return 'dr';
  }

  if (host === 'migration.esubcloud.com' || host === 'www.migration.esubcloud.com') {
    return 'migration';
  }

  if (host === 'stage.esubcloud.com' || host === 'www.stage.esubcloud.com') {
    return 'stage';
  }

  if (host === 'qa.esubcloud.com' || host === 'www.qa.esubcloud.com') {
    return 'qa';
  }

  return 'dev';
};

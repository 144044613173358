import { ReactSortableItem } from '@esub-engineering/react-component-library';
import { useState, useCallback } from 'react';

export const useSortableItem = (initialItems?: ReactSortableItem[]) => {
  const [list, setList] = useState<ReactSortableItem[]>(initialItems || []);

  const filesToInclude = useCallback(() => {
    const files = list
      .map((file) => {
        if (!file.checked) {
          return undefined;
        }
        if (file.name) {
          return file.name;
        }
        return undefined;
      })
      .filter((file) => file !== undefined) as string[];
    return files;
  }, [list]);

  const handleCheckAll = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, value: ReactSortableItem[]) => {
      setList(value);
    },
    [setList]
  );

  return { list, setList, handleCheckAll, filesToInclude };
};

import _get from 'lodash/get';

/**
 * Add formatting to page title if data exists and a record count can be parsed from this data
 * Otherwise - use the title without additional information
 *
 * @note this could also be done at the component level, although it was easier to use string interpolation with the existing features
 */
export const getPageTitleWithRecordCounts = (
  title: string,
  data: any,
  path: string // path to record count
) => {
  // attempt to resolve count from given data and path
  const count = _get(data, `${path}`);
  const countNum = Number(count);

  return !Number.isNaN(countNum) && countNum >= 0 ? `${title} (${count})` : title;
};

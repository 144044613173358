import { themes } from '@esub-engineering/react-component-library';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const { materialColors } = themes;

export default makeStyles(() =>
  createStyles({
    root: {
      padding: '0',
      textDecoration: 'underline',
      color: materialColors.blue[600],
      fontSize: '12px',
      fontWeight: 600,
    },
  })
);

import { IUseGraphQueryProps } from '../useGraphQuery/types';
import { useGraphQuery } from '../useGraphQuery';

export interface UseCachedGraphQueryOptions<TRequestData, TData>
  extends IUseGraphQueryProps<TRequestData, Error, TData> {
  key: string | string[];
}

/**
 * useGraphQuery with cache support, and default caching behaviors
 */
export const useCachedGraphQuery = <TRequestData, TData, TVariables>({
  staleTime = 1000 * 60 * 3,
  cacheTime = 1000 * 60 * 5,
  refetchOnMount = false,
  ...rest
}: UseCachedGraphQueryOptions<TRequestData, TData>) =>
  useGraphQuery<TRequestData, Error, TData, TVariables>({
    ...rest,
    refetchOnMount,
    staleTime,
    cacheTime,
  });

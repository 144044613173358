export const hoursAndMinutes = (date?: string | Date) => {
  if (!date) return '';

  const newDate = typeof date === 'string' ? new Date(date) : date;
  const hours = newDate.getHours() % 12;
  const minutes = newDate.getMinutes();
  const ampm = newDate.getHours() >= 12 ? 'PM' : 'AM';

  const convertedHours = hours !== 0 ? hours : 12;
  const convertedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;

  return `${convertedHours}:${convertedMinutes} ${ampm}`;
};

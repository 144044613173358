import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useGraphQLClient } from '@esub-engineering/common-containers';
import { useAuth } from '../../../auth/hooks';

/**
 * Sync and verify token wrapper
 * @todo need to unify the approaches with current user
 * @param props
 * @returns
 */
export const SyncToken = ({ children }: { children: React.ReactNode | React.ReactElement }) => {
  const location = useLocation();

  const { syncAndVerifyTokens, getIdToken } = useAuth();

  // when the location changes we want to verify tokens, and potentially request a refresh
  useEffect(() => {
    syncAndVerifyTokens();
  }, [location, syncAndVerifyTokens]);

  // when location changes, and id token should be synced -- we want to set all future graph authorization calls
  const client = useGraphQLClient();

  useEffect(() => {
    const idToken = getIdToken();

    if (idToken) {
      client?.setHeader('Authorization', idToken);
    }
  }, [client, getIdToken]);

  return <>{children}</>;
};

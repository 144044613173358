export type IsInEditWindowProps = {
  itemDate?: Date | string;
  windowSize?: number;
  noLimit?: boolean
}

export const isInEditWindow = ({ itemDate, windowSize = 8, noLimit }: IsInEditWindowProps) => {
  if (noLimit) return true

  if (!itemDate) return false

  const currentDate = new Date();
  const itemDatePlusWindow = new Date(itemDate);
  itemDatePlusWindow.setDate(itemDatePlusWindow.getDate() + windowSize)

  const currentMili = currentDate.getTime()
  const windowMili = itemDatePlusWindow.getTime()

  return currentMili < windowMili
};

import { useState, useCallback, useMemo } from 'react';
import axios, { AxiosResponse } from 'axios';
import { usePresignedLinkGenerator } from '../usePresignedLinkGenerator';
import {
  TUseFileUploaderProps,
  TUseFileUploaderResult,
  TUploadFilesProps,
  TUploadFilesResponse,
} from './types';
import { useCurrentUser } from '../../../auth/hooks';

export const useFileUploader = ({
  gql,
  queryKey,
}: TUseFileUploaderProps): TUseFileUploaderResult => {
  const { currentUser } = useCurrentUser();

  const [isFilesLoading, setIsLoading] = useState<boolean>(false);
  const [isFilesError, setIsFilesError] = useState<Error>();

  const { getPresignedLinks, isLinksLoading, isLinksError } = usePresignedLinkGenerator({
    key: queryKey,
    gql,
  });

  const isLoading = useMemo(() => isFilesLoading || isLinksLoading, [
    isFilesLoading,
    isLinksLoading,
  ]);

  const isError = useMemo(() => Boolean(isFilesError) || Boolean(isLinksError), [
    isFilesError,
    isLinksError,
  ]);

  const uploadFiles = useCallback(
    async ({
      apiQueryNamespace,
      id,
      files,
      idKeyPair,
    }: TUploadFilesProps): Promise<TUploadFilesResponse> => {
      try {
        const presignedLinks = await getPresignedLinks({ files, idKeyPair });

        const hasLinks = Array.isArray(presignedLinks);

        if (isLinksError || !currentUser?.token?.['custom:orgId']) throw new Error();

        const orgId = currentUser.token['custom:orgId'];
        const filePromises =
          hasLinks &&
          presignedLinks.map((presignedLink, index) => {
            const {
              [apiQueryNamespace]: {
                key,
                link,
                algorithm,
                credential,
                date,
                signature,
                token,
                policy,
              },
            } = presignedLink;
            const formData = new FormData();
            formData.append('key', key);
            formData.append('X-Amz-Algorithm', algorithm);
            formData.append('X-Amz-Credential', credential);
            formData.append('X-Amz-Date', date);
            formData.append('X-Amz-Signature', signature);
            formData.append('X-Amz-Security-Token', token);
            formData.append('Policy', policy);
            formData.append(`x-amz-meta-${Object.keys(idKeyPair)[0]}`, id);
            formData.append('x-amz-meta-orgId', orgId);
            formData.append('file', files[index]);

            return axios.post(link, formData, {
              headers: {
                'Content-type': files[index].type,
              },
            });
          });

        setIsLoading(true);

        if (filePromises) {
          const uploads = await Promise.all<AxiosResponse<any>>(filePromises);

          return {
            uploads,
            links: presignedLinks,
          };
        }
      } catch (error) {
        setIsFilesError(error as Error);
        throw error;
      }

      setIsLoading(false);

      return {
        uploads: undefined,
        links: undefined,
      };
    },
    [currentUser, getPresignedLinks, isLinksError]
  );

  return {
    uploadFiles,
    isFilesLoading,
    isLinksLoading,
    isFilesError,
    isLinksError,
    isLoading,
    isError,
  };
};

import { useGraphQuery, useToast } from '../../../common/hooks';
import { getVersionSetPdfJob, key } from '../../API/queries';
import { Return, TData, UseGetVersionSetPdfJobProps, VersionSetPdfJobStatus } from './types';

export const useGetVersionSetPdfJob = ({ id, projectId, ...rest }: UseGetVersionSetPdfJobProps) => {
  const { openErrorToast } = useToast();
  const responseTForm = (d: Return): TData => ({ ...d.getVersionSetPdfJob });

  return useGraphQuery<Return, Error, TData, [string, string]>({
    key: [key, id],
    gql: getVersionSetPdfJob,
    variables: { id, projectId },
    responseTForm,
    onError: () => openErrorToast('Unable to retrieve PDF job info.'),
    validateClientResponseData: (d) => {
      if (d.getVersionSetPdfJob.status === VersionSetPdfJobStatus.SUCCEEDED) return true;
      if (d.getVersionSetPdfJob.status === VersionSetPdfJobStatus.FAILED) return true;
      return false;
    },
    retry: true,
    retryDelay: 1000,
    ...rest,
  });
};

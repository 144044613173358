import { paths as fieldNotesPaths } from '../FieldNotes/paths';
import { paths as dailyReportsPaths } from '../DailyReports/paths';
import { paths as RFIsPaths } from '../RFIs/paths';
import { paths as changeOrdersPaths } from '../ChangeOrders/paths';
import { paths as purchaseOrdersPaths } from '../PurchaseOrders/paths';

export const paths = [
  '/documents',
  ...fieldNotesPaths,
  ...dailyReportsPaths,
  ...RFIsPaths,
  ...changeOrdersPaths,
  ...purchaseOrdersPaths,
];

import { TCurrentUser, TCurrentUserAction } from './types';

export type TCurrentUserActionFn = (arg: Partial<TCurrentUser>) => {
  type: TCurrentUserAction;
  payload: Partial<TCurrentUser>;
};

export const setUser: TCurrentUserActionFn = (payload) => ({
  type: 'SET_USER',
  payload,
});

import { ContactSearchResults } from '@esub-engineering/platform-types';
import { useGraphQLClient } from '@esub-engineering/common-containers';
import { ItemReturn } from '@esub-engineering/react-common-lib';
import { useCallback } from 'react';
import { QueryFunction } from 'react-query';
import { searchContacts } from '../../../fieldNote/api/queries';
import { useGraphQuery } from '../useGraphQuery';
import { IUseGraphQueryProps } from '../useGraphQuery/types';
import { useInfiniteQueryV2 } from '../useInfiniteQueryV2';

type ContactReturn = ItemReturn<'searchContacts', ContactSearchResults>;

interface ISearchQuery extends Partial<IUseGraphQueryProps> {
  variables: {
    term: string;
    limit?: number;
    page?: number;
    filter?: any;
    sortBy?: string;
    direction?: string;
  };
}

export const useSearchContacts = ({ ...rest }: ISearchQuery) =>
  useGraphQuery({
    key: ['searchContacts', rest.variables.term, JSON.stringify(rest.variables)],
    gql: searchContacts,
    ...rest,
  });

export const useSearchContactsV2 = ({ variables }: ISearchQuery) => {
  const client = useGraphQLClient();
  const query: QueryFunction<any> = useCallback(
    async ({ pageParam = 1 }) => {
      const data = await client!.request<ContactReturn>(searchContacts, {
        ...variables,
        limit: variables.limit || 10,
        page: pageParam || variables.page || 1,
      });
      const {
        searchContacts: { items, pagination },
      } = data;
      return { items: items.map((item) => ({ ...item, id: item.document.id })), pagination };
    },
    [client, variables]
  );

  return useInfiniteQueryV2({
    queryKey: ['searchContacts', variables.term, JSON.stringify(variables)],
    query,
    staleTime: 5 * 60 * 1000, // Matching the default cache value of this hook to avoid innecesary requests
  });
};

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Box, Paper } from '@material-ui/core';
import { Button, LoadingIndicator, DataGrid, CrudFlow } from '@esub-engineering/react-component-library';
import { useImportFromCSV } from '../../hooks/useImportFromCSV';
import useStyles from './styles';
import { IImportFlow } from './types';

export const ImportFlow = React.memo(
  ({
    onClose,
    saveImportData,
    submittingForm,
    title,
    columns,
    schemaMapping,
    saveButtonId,
    templateButtonProps,
    onReady,
    Instructions,
    customValidation = undefined,
  }: IImportFlow) => {
    const classes = useStyles();
    const formRef = useRef<HTMLFormElement>(null);

    const [dataSource, setDataSource] = useState<any[]>([]);

    const transformHeader = useCallback(
      (header: string) => header.toLowerCase().replaceAll(' ', ''),
      []
    );

    const { data: importedData, FileImport, selectedFile, isLoading } = useImportFromCSV({
      header: true,
      skipEmptyLines: true,
      transformHeader,
    });

    const isFormValid = useMemo(
      () =>
        dataSource.every((row) =>
          Object.values(row?.errors || {}).every((err) => err === undefined)
        ),
      [dataSource]
    );

    const validateField = useCallback(
      async ({ fieldValue, fieldName }) => {
        try {
          const schema = schemaMapping[fieldName as keyof typeof schemaMapping];

          await schema.validate(fieldValue);

          return undefined;
        } catch (err: any) {
          return err.message;
        }
      },
      [schemaMapping]
    );

    const runCustomValidation = useCallback(
      (columnId: string, value: string, rowData: any) => {
        let customError: string | undefined;

        if (customValidation && customValidation[columnId]) {
          customError = customValidation[columnId](value, rowData);
        }

        return customError;
      },
      [customValidation]
    );

    const updateDataSource = ({
      rowIndex,
      prevData,
      columnId,
      fieldValue,
    }: {
      rowIndex: number;
      prevData: any[];
      columnId: string;
      fieldValue: string;
    }) => {
      const data = [...prevData];
      data[rowIndex][columnId] = fieldValue;
      return data;
    };

    const handleFieldUpdate = useCallback(
      async ({
        columnId,
        rowIndex,
        value,
      }: {
        columnId: string;
        rowIndex: number;
        value: string;
      }) => {
        const fieldError = await validateField({
          fieldValue: value,
          fieldName: columnId,
        });

        let customError;

        if (customValidation) {
          customError = runCustomValidation(columnId, value, dataSource[rowIndex]);
        }

        const data = updateDataSource({
          rowIndex,
          prevData: dataSource,
          columnId,
          fieldValue: value,
        });

        data[rowIndex] = {
          ...data[rowIndex],
          errors: { ...data[rowIndex].errors, [columnId]: fieldError || customError },
        };

        setDataSource(data);
      },
      [dataSource, validateField, runCustomValidation, customValidation]
    );

    const prepareImportedData = useCallback(
      async (data: any[]) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle
        const _data: any[] = [...data];

        await Promise.all(
          _data.map(async (obj, rowIndex) => {
            const dataEntries = Object.entries(obj);

            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < dataEntries.length; i++) {
              const [key, value] = dataEntries[i];

              // eslint-disable-next-line no-await-in-loop
              const fieldError = await validateField({
                fieldValue: value,
                fieldName: key,
              });

              let customError;

              if (customValidation) {
                customError = runCustomValidation(key, value as string, _data[rowIndex]);
              }

              _data[rowIndex] = {
                ..._data[rowIndex],
                errors: { ..._data[rowIndex]?.errors, [key]: fieldError || customError },
              };
            }
          })
        );

        setDataSource(_data);
      },
      [validateField, customValidation, runCustomValidation]
    );

    useEffect(() => {
      if (importedData && !!importedData.length) {
        prepareImportedData(importedData);
      }
    }, [importedData, prepareImportedData]);

    const handleSubmit = useCallback(
      (e) => {
        e.preventDefault();

        if (!isFormValid) {
          return;
        }

        saveImportData(dataSource);
      },
      [dataSource, isFormValid, saveImportData]
    );

    return (
      <CrudFlow
        title={title}
        idPrefix="Import"
        onClose={() => onClose()}
        standalone
        dirty
        additionalButtons={[
          {
            id: saveButtonId,
            children: 'Save',
            type: 'submit',
            onClick: () => {
              formRef.current?.requestSubmit();
            },
            loading: submittingForm,
            variant: 'contained',
            disabled: !isFormValid,
          },
        ]}
      >
        <form ref={formRef} onSubmit={handleSubmit}>
          <FileImport />
          <span className={classes.fileSelection}>{selectedFile?.name || 'No file selected.'}</span>
          <Box marginTop="10px" marginLeft="4px">
            Please use the{' '}
            <Button {...templateButtonProps} asLink>
              File template
            </Button>{' '}
            provided by eSUB
          </Box>
          {Instructions && <Instructions />}
          {isLoading && <LoadingIndicator loading />}
          {!!importedData.length && !isLoading && (
            <Box marginTop="20px">
              <Paper elevation={3} style={{ overflow: 'hidden', borderRadius: '15px' }}>
                {/* @ts-ignore */}
                <DataGrid
                  onReady={onReady}
                  idProperty="import"
                  showHeader
                  licenseKey={process.env.REACT_APP_REACT_DATA_GRID_LICENSE}
                  style={{
                    minHeight: 'calc(100vh - 250px)',
                    maxHeight: 'calc(100vh - 250px)',
                    cursor: 'pointer',
                  }}
                  rowHeight={90}
                  onRowReorder={undefined}
                  emptyText="No records to show"
                  columns={columns}
                  // @ts-ignore
                  dataSource={dataSource}
                  showZebraRows
                  scrollThreshold={0.7}
                  activateRowOnFocus={false}
                  // @ts-ignore
                  onEditComplete={handleFieldUpdate}
                  editable
                />
              </Paper>
            </Box>
          )}
        </form>
      </CrudFlow>
    );
  }
);

import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import {
  setProgress,
  openProgress,
  closeProgress,
  resetProgress,
  ProgressIndicatorState,
} from '../../redux/progressIndicator';

export const useProgressIndicator = () => {
  const timer = useRef<ReturnType<typeof setTimeout> | null>(null);

  const dispatch = useDispatch();

  const setProgressIndicator = (state: ProgressIndicatorState) => dispatch(setProgress(state));

  const openProgressIndicator = (state: ProgressIndicatorState) => dispatch(openProgress(state));

  const closeProgressIndicator = () => {
    if (timer?.current) {
      clearTimeout(timer?.current);
    }

    // close progress indicator after 5sec
    timer.current = setTimeout(() => dispatch(closeProgress()), 5000);
  };

  const resetProgressIndicator = () => dispatch(resetProgress());

  useEffect(
    () => () => {
      if (timer.current) {
        clearTimeout(timer.current);
        timer.current = null;
      }
    },
    []
  );

  return {
    setProgressIndicator,
    openProgressIndicator,
    closeProgressIndicator,
    resetProgressIndicator,
  };
};

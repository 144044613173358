import React from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { LoadingIndicator } from '@esub-engineering/react-component-library';
import { Organization } from '@esub-engineering/platform-types';
import { getMyOrganization, key } from '@esub-engineering/react-common-lib/dist/api/queries/organizations';
import { useGraphQLClient } from '@esub-engineering/common-containers';
import { setOrganizationInfo } from '../../../organization/redux';

type Props = {
  children: React.ReactNode | React.ReactChild | React.ReactChildren;
};

export const InitOrganization = React.memo(({ children }: Props) => {
  const dispatch = useDispatch();
  const client = useGraphQLClient();

  const setOrgStore = React.useCallback(
    (data: { getMyOrganization: Organization }) => {
      dispatch(setOrganizationInfo(data?.getMyOrganization));
    },
    [dispatch]
  );

  const modulesQuery = React.useCallback(async () => {
    try {
      const result = await client!.request(getMyOrganization);

      return result;
    } catch (e) {
      return null;
    }
  }, [client]);

  const { isLoading } = useQuery<{ getMyOrganization: Organization }, Error>(key, modulesQuery, {
    refetchOnWindowFocus: false,
    onSuccess: (data) => setOrgStore(data),
  });

  return (
    <LoadingIndicator loading={isLoading} fullscreen>
      {children}
    </LoadingIndicator>
  );
});

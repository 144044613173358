import { TEntityAssignment } from "../../types"

export const SET_STICKY_ENTITY_ASSIGNMENTS = 'SET_STICKY_ENTITY_ASSIGNMENTS'
export const CLEAR_STICKY_ENTITY_ASSIGNMENTS = 'CLEAR_STICKY_ENTITY_ASSIGNMENTS'

export const setStickyEntityAssignments = (payload: TEntityAssignment[]) => ({
  type: SET_STICKY_ENTITY_ASSIGNMENTS,
  payload
})

export const clearStickyEntityAssignments = () => ({
  type: CLEAR_STICKY_ENTITY_ASSIGNMENTS
})

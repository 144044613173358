import React from 'react';
import { datadogRum } from '@datadog/browser-rum';
import { getStageFromLocation } from '@esub-engineering/react-common-lib';
import { useCurrentUser } from '../../../auth/hooks';

/**
 * Hook for datadog initialization and support
 */
export const useDatadog = () => {
  // get some runtime vars
  const { NODE_ENV } = process.env;

  const stage = getStageFromLocation(window.location.hostname);

  // get current user data for rum context
  const { data } = useCurrentUser();

  // determine if datadog should be enabled or not
  const enabled = stage !== 'branch' && NODE_ENV === 'production';

  // @ts-ignore-next-line
  const isInitialized = Boolean(window?.DD_RUM);

  // initialize datadog only if enabled
  // const init = React.useCallback(() => {
  //   if (enabled && !isInitialized) {
  //     datadogRum.init({
  //       applicationId: 'e115cc1f-41a5-4889-b2ce-d602d2f54953',
  //       clientToken: 'pub2d8acc497129b03bc32eab37ef212d0b',
  //       site: 'datadoghq.com',
  //       service: 'esub-cloud-customer-web-app',
  //       env: stage,
  //       // Specify a version number to identify the deployed version of your application in Datadog
  //       version: REACT_APP_APPLICATION_VERSION,
  //       sampleRate: 100,
  //       replaySampleRate: 100,
  //       defaultPrivacyLevel: 'allow',
  //       trackInteractions: true,
  //       allowedTracingOrigins: [/https:\/\/.*\.esubcloud\.com/],
  //     });
  //   }

  //   // enable datadog session replays for desired environments
  //   if (stage === 'qa' && isInitialized) {
  //     // eslint-disable-next-line no-console
  //     console.log('DD session replays enabled');
  //     datadogRum.startSessionReplayRecording();
  //   }
  // }, [REACT_APP_APPLICATION_VERSION, enabled, isInitialized, stage]);

  // set user context only if enabled
  const setUserContext = React.useCallback(() => {
    if (enabled && isInitialized) {
      datadogRum.setUser({
        name:
          data?.givenName && data?.familyName ? `${data.givenName} ${data.familyName}` : undefined,
        email: data?.email,
      });

      datadogRum.addRumGlobalContext('currentUser', {
        ...data,
      });
    }
  }, [enabled, data, isInitialized]);

  const DataDog = () => {
    // React.useEffect(init, []);
    React.useEffect(setUserContext, [data]);

    return null;
  };

  return {
    DataDog,
    enabled,
    // init,
    setUserContext,
  };
};

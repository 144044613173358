import { ConfirmDialog } from '@esub-engineering/react-component-library';
import React, { useCallback } from 'react';

export type UseDeleteConfirmProps = {
  onDeleteConfirm: (id: string) => Promise<void> | void;
  deleting?: boolean;
  entityName: string;
  customMessage?: React.ReactNode;
};

/**
 * Delete confirm component and functionality with way less hassle
 */
export const useDeleteConfirm = ({
  onDeleteConfirm,
  entityName,
  deleting,
  customMessage,
}: UseDeleteConfirmProps) => {
  const [deleteId, setDeleteId] = React.useState<string | null | undefined>(null);

  const cancelDelete = useCallback(() => setDeleteId(null), []);

  const promptForDelete = useCallback((id: string) => setDeleteId(id), []);

  const DeleteConfirmDialog = React.memo(() => (
    <ConfirmDialog
      title="Are you sure..."
      open={Boolean(deleteId)}
      onCancel={cancelDelete}
      disablePortal={false}
      onConfirm={async () => {
        if (deleteId) {
          await onDeleteConfirm(deleteId);
          cancelDelete();
        }
      }}
      confirmText="Continue"
      cancelText="Cancel"
      loading={deleting}
      style={{
        zIndex: 1301,
      }}
    >
      <>
        {customMessage || <p>This {entityName} will be deleted and cannot be undone.</p>}

        <p>Click &apos;Continue&apos; to proceed.</p>
      </>
    </ConfirmDialog>
  ));

  return { DeleteConfirmDialog, deleteId, cancelDelete, promptForDelete };
};

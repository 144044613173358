import React from 'react';
import { useQueryClient, QueryKey } from 'react-query';
import { TypeSortInfo } from '@inovua/reactdatagrid-enterprise/types';
import { useToast } from '../useToast';

export namespace UseDataSourceQuery {
  export type FetchProps = {
    skip: number;
    limit: number;
    sortInfo: TypeSortInfo;
    filter?: any;
  };

  export type LivePaginationData = {
    data: any[];
    count: number;
  };

  type QueryResult<
    TQueryData extends LivePaginationData = LivePaginationData
  > = Promise<TQueryData>;

  export type Options<
    TQueryData extends LivePaginationData = LivePaginationData,
    TError = Error
  > = {
    maxDataLength?: number;
    toastErrors?: boolean;

    // event callbacks
    onError?: (ex: TError) => void;
    onSuccess?: (result: TQueryData) => void;
    onSettled?: () => void;
  };

  export type QueryFn<TQueryData extends LivePaginationData = LivePaginationData> = (
    props?: Partial<FetchProps> & {
      props?: any;
    }
  ) => QueryResult<TQueryData>;
}

/**
 * Query wrapper for reactdatagrid dataSource signature { limit, skip, sortInfo }
 * Appends to the cache with each subsequent request.
 *
 * @see https://reactdatagrid.io/docs/live-pagination
 *
 * @todo on initial load we can prefetch the data from the cache, using skip or a counter value
 * @todo utilize fetchQuery from react-query for extended retry/error support
 * @todo transform data before cache and return in datasource
 */
export const useDataSourceQuery = <
  TQueryData extends UseDataSourceQuery.LivePaginationData = UseDataSourceQuery.LivePaginationData,
  TError = Error
>(
  key: QueryKey,
  fetchFn: UseDataSourceQuery.QueryFn<TQueryData>,
  {
    toastErrors,
    onError = () => {},
    onSuccess = () => {},
    onSettled = () => {},
  }: UseDataSourceQuery.Options<TQueryData, TError> = {}
) => {
  const client = useQueryClient();

  const { openErrorToast } = useToast();

  const appendData = React.useCallback(
    (data: any[]) => {
      client.setQueryData<any[]>(key, (oldData: any[] | undefined) =>
        oldData ? [...oldData, ...data] : data
      );
    },
    [client, key]
  );

  const fetchData = React.useCallback(
    (props) => async ({ skip, limit, sortInfo }: UseDataSourceQuery.FetchProps) => {
      let result;
      try {
        // TODO: this should be the way but it is only keeping 2 sets in cache
        // result = await client.fetchQuery<TQueryData>(key, (context) => {
        //   console.log(context);
        //   return fetchFn();
        // });
        result = await fetchFn({ skip, limit, sortInfo, props });
        onSuccess(result);
      } catch (ex: any) {
        if (toastErrors) {
          openErrorToast('Unable to retrieve User Timecards');
        }

        onError(ex);
        return undefined;
      } finally {
        onSettled();
      }

      appendData(result.data);

      return result;
    },
    [appendData, fetchFn, onError, onSettled, onSuccess, openErrorToast, toastErrors]
  );

  const removeQuery = React.useCallback(() => {
    client.removeQueries(key);
  }, [client, key]);

  return {
    fetch: fetchData,
    removeQuery,
    // isLoading,
  };
};

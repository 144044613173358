import * as ACTIONS from './actions';
import { TEntityAssignment } from '../../types';

export const initialState: Array<TEntityAssignment> = [];

// eslint-disable-next-line @typescript-eslint/default-param-last
export default (state = initialState, { type, payload }: any) => {
  switch (type) {
    case ACTIONS.SET_STICKY_ENTITY_ASSIGNMENTS:
      return [...payload];
    case ACTIONS.CLEAR_STICKY_ENTITY_ASSIGNMENTS:
      return [];
    default:
      return state;
  }
};

import { ToastType, ToastActionPayloadType } from './types';

const initialState: ToastType = {
  open: false,
  message: '',
  autoHideDuration: 6000,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export default (state = initialState, { type, payload }: ToastActionPayloadType) => {
  switch (type) {
    case 'SET_TOAST':
      return {
        ...state,
        ...payload,
      };
    case 'OPEN_TOAST':
      return {
        ...state,
        ...payload,
        open: true,
      };
    case 'CLOSE_TOAST':
      return {
        ...state,
        ...payload,
        open: false,
      };
    case 'RESET_TOAST':
      return initialState;
    default:
      return state;
  }
};

import React, {
  createContext,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import _get from 'lodash/get';
import _uniq from 'lodash/uniq';
import { searchLimit } from '../../api';
import { MappedFilterOptions, SortOptions } from './types';

type FilterValue = string | boolean;

type SearchOptionsProviderProps = {
  persistKey?: string;
  initialFilterOptions?: MappedFilterOptions;
  initialSortOptions?: SortOptions;
  children: React.ReactNode | React.ReactElement;
};

type SearchOptionsContextProps = {
  persistKey?: string;
  filterValues?: MappedFilterOptions;
  tempFilterValues?: MappedFilterOptions;
  searchTerm: string;
  addFilterValue: (key: string, value: FilterValue) => void;
  replaceFilterValue: (key: string, value: FilterValue) => void;
  replaceFilterValues: (key: string, values: FilterValue[]) => void;
  removeFilterValue: (key: string, value: FilterValue) => void;
  setFilterValues: React.Dispatch<SetStateAction<MappedFilterOptions>>;
  clearFiltersOptions: () => void;
  clearAllFilterValues: () => void;
  clearFilterValuesByKey: (key: string) => void;

  sortValues?: SortOptions;
  tempSortValues?: SortOptions;
  setSortValues: React.Dispatch<SetStateAction<SortOptions>>;
  clearSortOptions: () => void;
  sortByKey: (key: string, direction: 'ASC' | 'DESC') => void;
  clear: (clearPersistedValues?: boolean) => void;
  persist: (persistToStorage?: boolean) => void;
  updateSearchTerm: (term: string) => void;
  clearSearchTerm: (key?: string) => void;

  searchLimit: number;
};

const defaultSortOptions: SortOptions = {
  sortBy: undefined,
  direction: 'ASC',
};

const defaultFilterOptions: MappedFilterOptions = {};

export const SearchOptionsContext = createContext<SearchOptionsContextProps>({
  persistKey: '',
  filterValues: {},
  tempFilterValues: {},
  searchTerm: '',
  setFilterValues: () => {},
  addFilterValue: () => {},
  replaceFilterValue: () => {},
  replaceFilterValues: () => {},
  removeFilterValue: () => {},
  clearFiltersOptions: () => {},
  clearAllFilterValues: () => {},
  clearFilterValuesByKey: () => {},
  sortValues: {},
  tempSortValues: {},
  setSortValues: () => {},
  sortByKey: () => {},
  clearSortOptions: () => {},
  clear: () => {},
  persist: () => {},
  updateSearchTerm: () => {},
  clearSearchTerm: () => {},
  searchLimit,
});

/**
 * Provider used to persist, and manage search options under a single 'key' which is usually an entity name
 * * Use optional initialFilterOptions if we want to preload initial filter options
 * * Use optional initialSortOptions if we want to preload initial sort options
 */
export const SearchOptionsProvider = React.memo(
  ({
    persistKey,
    initialFilterOptions = defaultFilterOptions,
    initialSortOptions = defaultSortOptions,
    children,
  }: SearchOptionsProviderProps) => {
    const searchTermKey = useMemo(() => `${persistKey}.searchTerm`, [persistKey]);
    const [searchTerm, setSearchTerm] = useState<string>(
      () => localStorage.getItem(searchTermKey) || ''
    );
    const [tempSortValues, setTempSortValues] = useState<SortOptions>(initialSortOptions);
    const [tempFilterValues, setTempFilterValues] = useState<MappedFilterOptions>(
      initialFilterOptions
    );

    const [sortValues, setSortValues] = useState<SortOptions>(initialSortOptions);
    const [filterValues, setFilterValues] = useState<MappedFilterOptions>(initialFilterOptions);

    const persistedState = React.useMemo(() => {
      if (!persistKey) return null;

      const storedState = localStorage.getItem(persistKey);

      if (!storedState) return null;

      let parsedState: {
        sortValues: SortOptions;
        filterValues: MappedFilterOptions;
      };

      try {
        parsedState = JSON.parse(storedState) as {
          sortValues: SortOptions;
          filterValues: MappedFilterOptions;
        };
      } catch (ex) {
        parsedState = {
          sortValues: defaultSortOptions,
          filterValues: defaultFilterOptions,
        };
      }

      return parsedState;
    }, [persistKey]);

    // sync values with persisted state AFTER MOUNT ONLY
    useEffect(() => {
      // set sort state from localstorage
      if (persistedState && persistedState?.sortValues?.sortBy) {
        setSortValues(persistedState.sortValues);
        setTempSortValues(persistedState.sortValues);
      }

      // set filter state from localstorage
      if (persistedState && persistedState?.filterValues) {
        setFilterValues(persistedState.filterValues);
        setTempFilterValues(persistedState.filterValues);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Persist and optionally apply values to local storage and new state
     */
    const persist = React.useCallback(
      (persistToStorage: boolean = true) => {
        setSortValues(tempSortValues);
        setFilterValues(tempFilterValues);

        if (persistKey && persistToStorage) {
          localStorage.setItem(
            persistKey,
            JSON.stringify({
              sortValues: tempSortValues,
              filterValues: tempFilterValues,
            })
          );
        }
      },
      [persistKey, tempSortValues, tempFilterValues]
    );

    /**
     * Update search term
     */
    const updateSearchTerm = useCallback(
      (term: string) => {
        setSearchTerm(term);

        // persist to local storage
        localStorage.setItem(searchTermKey, term);
      },
      [searchTermKey]
    );

    /**
     * Clear search term
     */
    const clearSearchTerm = useCallback(() => {
      setSearchTerm('');
      localStorage.removeItem(searchTermKey);
    }, [searchTermKey]);

    // sort state methods

    /**
     * Set sortBy key at given direction
     */
    const sortByKey = React.useCallback((sortBy: string, direction: 'ASC' | 'DESC' = 'ASC') => {
      setTempSortValues({
        sortBy,
        direction,
      });
    }, []);

    /**
     * Set sort state to undefined
     */
    const clearSortOptions = React.useCallback(() => {
      setTempSortValues({});
    }, []);

    // filter state methods

    /**
     * add a filter value to key
     */
    const addFilterValue = React.useCallback((key: string, value: FilterValue) => {
      setTempFilterValues((prev) => {
        const currentValues = _get(prev, key, []);
        return {
          ...prev,
          [key]: [..._uniq(currentValues), value],
        };
      });
    }, []);

    /**
     * remove filter value from key
     */
    const removeFilterValue = React.useCallback((key: string, value: FilterValue) => {
      setTempFilterValues((prev) => ({
        ...prev,
        [key]: _get(prev, key, []).filter((item: any) => item !== value),
      }));
    }, []);

    /**
     * replace filter value
     * - useful for options without multiple values
     */
    const replaceFilterValue = React.useCallback((key: string, value: FilterValue) => {
      setTempFilterValues((prev) => ({
        ...prev,
        [key]: [value],
      }));
    }, []);

    /**
     * replace filter values
     * - useful for options without multiple values
     */
    const replaceFilterValues = React.useCallback((key: string, values: FilterValue[]) => {
      setTempFilterValues((prev) => ({
        ...prev,
        [key]: values,
      }));
    }, []);

    /**
     * Set all the filter values to undefined
     */
    const clearAllFilterValues = React.useCallback(() => {
      setTempFilterValues((prev) => ({
        ...Object.keys(prev).reduce(
          (acc, key) => ({
            ...acc,
            [key]: undefined,
          }),
          {}
        ),
      }));
    }, []);

    /**
     * Set filter value to undefined at given key
     */
    const clearFilterValuesByKey = React.useCallback((key: string) => {
      setTempFilterValues((prev) => ({
        ...prev,
        [key]: undefined,
      }));
    }, []);

    /**
     * Set filters to undefined
     */
    const clearFiltersOptions = React.useCallback(() => {
      setTempFilterValues({});
    }, []);

    // state utils

    /**
     * Set filter and sort to undefined
     */
    const clear = React.useCallback(
      (clearPersistedValues: boolean = false) => {
        clearFiltersOptions();
        clearSortOptions();

        if (clearPersistedValues && persistKey) localStorage.removeItem(persistKey);
      },
      [clearFiltersOptions, clearSortOptions, persistKey]
    );

    const value = React.useMemo(
      () => ({
        persistKey,
        filterValues,
        tempFilterValues,
        setFilterValues,
        addFilterValue,
        replaceFilterValue,
        replaceFilterValues,
        removeFilterValue,
        clearFiltersOptions,
        clearAllFilterValues,
        clearFilterValuesByKey,
        sortValues,
        tempSortValues,
        setSortValues,
        sortByKey,
        clearSortOptions,
        clear,
        persist,
        searchLimit,
        searchTerm,
        updateSearchTerm,
        clearSearchTerm,
      }),
      [
        persistKey,
        filterValues,
        tempFilterValues,
        setFilterValues,
        addFilterValue,
        replaceFilterValue,
        replaceFilterValues,
        removeFilterValue,
        clearFiltersOptions,
        clearAllFilterValues,
        clearFilterValuesByKey,
        sortValues,
        tempSortValues,
        setSortValues,
        sortByKey,
        clearSortOptions,
        clear,
        persist,
        searchTerm,
        updateSearchTerm,
        clearSearchTerm,
      ]
    );

    return <SearchOptionsContext.Provider value={value}>{children}</SearchOptionsContext.Provider>;
  }
);

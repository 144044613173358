import _get from 'lodash/get';

/**
 * Return only the fields that have defined values
 */
export const omitUndefined = (
  obj: { [key: string]: any },
  {
    treatEmptyStringsAsUndefined,
    treatNullAsUndefined,
    treatZeroAsUndefined,
  }: {
    treatEmptyStringsAsUndefined?: boolean;
    treatNullAsUndefined?: boolean;
    treatZeroAsUndefined?: boolean;
  } = {}
) => {
  // omit function to make it easier to add future conditions
  const shouldOmit = (value?: string | number) =>
    value === undefined ||
    (treatEmptyStringsAsUndefined && value === '') ||
    (treatNullAsUndefined && value === null) ||
    (treatZeroAsUndefined && value === 0);

  return Object.keys(obj).reduce(
    (acc, key) => ({
      ...acc,
      ...(shouldOmit(obj[key]) ? {} : { [key]: obj[key] }),
    }),
    {}
  );
};

/**
 * Return nulls for empty fields
 * * Useful for updating an entity as nulls will be removed
 */
export const convertUndefinedToNull = (
  obj: { [key: string]: any },
  { treatEmptyStringsAsNull }: { treatEmptyStringsAsNull?: boolean } = {}
) => {
  // nullify function to make it easier to add future conditions
  const shouldNullify = (value?: string) =>
    value === undefined || (treatEmptyStringsAsNull && value === '');

  return Object.keys(obj).reduce(
    (acc, key) => ({
      ...acc,
      ...(shouldNullify(obj[key]) ? { [key]: null } : { [key]: obj[key] }),
    }),
    {}
  );
};

/**
 * Convert an empty value in a potentially deeply nested path to null via custom empty validator
 */
export const convertEmptyToNull = <TObject = any, TValue = any | undefined>(
  obj: TObject,
  valuePath: string,
  {
    validateEmpty = (value: TValue) => value === null,
    treatUndefinedAsEmpty = true,
    treatEmptyStringAsEmpty = true,
  }: {
    validateEmpty?: (value: TValue) => boolean;
    treatUndefinedAsEmpty?: boolean;
    treatEmptyStringAsEmpty?: boolean;
  } = {}
) => {
  const value = _get(obj, valuePath);

  // optionally treat an undefined value as empty
  if (treatUndefinedAsEmpty && typeof value === 'undefined') return null;

  // optionally treat empty string value as empty
  if (treatEmptyStringAsEmpty && value === '') return null;

  // finally use validator to confirm if this value is empty
  if (validateEmpty(value)) return null;

  return value;
};

/**
 * Return object replacing undefined properties with empty string
 */
export const convertEmptyToString = (
  obj: { [key: string]: any },
  {
    treatNullAsEmpty,
  }: {
    treatNullAsEmpty?: boolean;
  } = {}
) => {
  // function to make it easier to add future conditions
  const shouldConvert = (value?: string | number) =>
    value === undefined || (treatNullAsEmpty && value === null);

  return Object.keys(obj).reduce(
    (acc, key) => ({
      ...acc,
      ...(shouldConvert(obj[key]) ? { [key]: '' } : { [key]: obj[key] }),
    }),
    {}
  );
};

import React from 'react';
import { TypeColumn, TypeComputedProps } from '@inovua/reactdatagrid-community/types';
import { UseDataGridInlineEditCell } from '../useDataGridInlineEditCell';

enum DataGridCellVariants {
  'text',
  'date',
  'chips',
  // TODO: autocomplete?
}

export namespace UseDataGrid {
  export type State = {
    editing?: boolean;
    updating?: boolean;

    columnIndex?: number;
    rowIndex?: number;

    skip?: number;
    groupBy?: any;
    filterValue?: null | any;
    limit?: number;
    sortInfo?: any;

    // TODO: change to total and set using a provided callback
    count?: number;
  };

  type CellVariants = DataGridCellVariants;

  export type Column = TypeColumn & {
    variant?: CellVariants;
    cellProps?: any;
    editorProps?: Partial<UseDataGridInlineEditCell.EditorProps>;
  };

  type DefaultDataType = object;

  type OptionalPromise<T extends object = object> = Promise<T> | T;

  type LivePaginatedParams = { skip: number; limit: number; sortInfo: any };

  type LivePaginatedData<T extends DefaultDataType = DefaultDataType> = OptionalPromise<{
    data: T[];
    count: number;
  }>;

  type Data<T extends DefaultDataType = DefaultDataType> = T[] | undefined;

  export type Props<T extends DefaultDataType = DefaultDataType> = {
    fetchNextPage?: (params: LivePaginatedParams) => LivePaginatedData<T> | Data<T>;
    persistCellValue?: (data: T) => Promise<T> | void;
    toastOnInvalid?: boolean;
  };
}

const initialState: UseDataGrid.State = {
  rowIndex: undefined,
  columnIndex: undefined,
  skip: undefined,
  editing: false,
  updating: false,
};

/**
 * Hook that adds utility to our DataGrid implementations with common state and interactions
 * @deprecated in favor of useDataGrid from provider
 */
export const useDataGrid = ({
  fetchNextPage = () => undefined,
  persistCellValue = () => {},
  toastOnInvalid = false,
}: UseDataGrid.Props) => {
  /** use ref to manipulate data within the grid */
  const [gridRef, setGridRef] = React.useState<{ current: TypeComputedProps } | null>();

  const [state, setState] = React.useState<UseDataGrid.State>(initialState);

  /**
   * Set active cell during edit
   */
  const setActiveCell = React.useCallback((row: number, col: number) => {
    setState((s) => ({
      ...s,
      rowIndex: row,
      columnIndex: col,
      editing: true,
    }));
  }, []);

  /**
   * Clear active cell after edit
   */
  const clearActiveCell = React.useCallback(() => {
    setState((s) => ({
      ...s,
      rowIndex: undefined,
      columnIndex: undefined,
      editing: false,
    }));
  }, []);

  /**
   * Flag update status
   */
  const beginUpdate = React.useCallback(() => {
    setState((s) => ({
      ...s,
      updating: true,
    }));
  }, []);

  /**
   * Clear update flag
   */
  const endUpdate = React.useCallback(() => {
    setState((s) => ({
      ...s,
      updating: false,
    }));
  }, []);

  /**
   * Factory to check if a cell is editable or not based on grid state and default params
   */
  const getEditable = React.useCallback(
    (value: any, cellProps: any) => {
      const { rowIndex, columnIndex, updating } = state;

      // allow edit because active indexes have not been set
      if (typeof rowIndex === 'undefined' || typeof columnIndex === 'undefined') {
        return true;
      }

      // if we aren't waiting for an update... allow edit to start on any cell
      if (!updating) return true;

      // otherwise compare the index values and lock edit to the current cell
      return rowIndex === cellProps.rowIndex && columnIndex === cellProps.columnIndex;
    },
    [state]
  );

  /**
   * Hydrate and memoize columns
   */
  const getColumnProps = React.useCallback(
    (editorProps = {}) => ({
      editable: getEditable,
      editorProps: {
        toastOnInvalid,
        onBeginUpdate: beginUpdate,
        onEndUpdate: endUpdate,
        persistCellValue,
        ...editorProps,
        gridRef,
      },
    }),
    [beginUpdate, endUpdate, getEditable, persistCellValue, toastOnInvalid, gridRef]
  );

  /**
   * dataSource function for live pagination in the grid
   */
  const dataSource = React.useCallback(
    async (props: any) => {
      // set the current skip amount incase we need it
      setState((s) => ({
        ...s,
        skip: props.skip,
      }));

      return fetchNextPage(props);
    },
    [fetchNextPage]
  );

  // wire up ref for reactdatagrid manipulations
  const onReady = React.useCallback((ref: any) => {
    setGridRef(ref);
  }, []);

  // set state for active editing cell
  const onEditStart = React.useCallback(
    (props: any) => {
      setActiveCell(props.rowIndex, props.columnIndex);
    },
    [setActiveCell]
  );

  /**
   * clear state for active editing cell
   */
  const onEditComplete = React.useCallback(() => {
    clearActiveCell();
  }, [clearActiveCell]);

  /**
   * clear state for active editing cell
   */
  const onEditCancel = React.useCallback(() => {
    clearActiveCell();
  }, [clearActiveCell]);

  /**
   * Define cellDOMProps for grid
   * Useful to enabling inline editing on single click
   */
  const cellDOMProps = React.useCallback(
    (cellProps: any) => ({
      onClick: () => {
        if (gridRef?.current && gridRef?.current?.startEdit) {
          gridRef.current.startEdit({ columnId: cellProps.id, rowIndex: cellProps.rowIndex });
        }
      },
    }),
    [gridRef]
  );

  /**
   * Props for grid usage usually spread into place
   */
  const gridProps = React.useMemo(
    () => ({
      dataSource,
      onReady,
      onEditStart,
      onEditComplete,
      onEditCancel,
      cellDOMProps,
    }),
    [dataSource, onEditCancel, onEditComplete, onEditStart, onReady, cellDOMProps]
  );

  /**
   * Remove row from data grid
   */
  const removeRowFromGrid = React.useCallback(
    (rowIndex: number) => {
      if (!gridRef?.current) return;

      const narr = gridRef?.current.data;

      if (!narr) {
        console.error('Unable to delete row. No grid data to manipulate.');
        return;
      }

      if (narr[rowIndex]) {
        gridRef.current.cancelEdit!({});

        narr.splice(rowIndex, 1);
        gridRef.current.silentSetData(narr);
      }
    },
    [gridRef]
  );

  /**
   * Find rowid by id
   */
  const findIndexById = React.useCallback(
    (id: string) => {
      if (gridRef?.current.data) {
        const i = gridRef?.current.data.findIndex((v) => v.id === id);

        return i !== -1 ? i : null;
      }

      return null;
    },
    [gridRef]
  );

  const resetState = React.useCallback(() => {
    setState(initialState);
  }, []);

  return {
    state,
    setState,
    resetState,
    gridRef,
    gridProps,
    getColumnProps,
    removeRowFromGrid,
    findIndexById,
  };
};

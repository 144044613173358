import React from 'react';
import { Grid } from '@material-ui/core';
import { DataGridField } from '../DataGridField';
import { DataGridDocumentLink } from '../DataGridDocumentLink';

type Props = {
  fields: any;
  documentName: string;
  path: string;
  showTopTitle?: boolean;
  logName: string;
  rightTopComponent?: any;
}

/**
 * DataGrid field for displaying custom row cells in columns
 */
export const DataGridRow = React.memo(({ fields, documentName, path, showTopTitle, logName, rightTopComponent }: Props) => (
    <Grid container direction="row" sm={12} spacing={1} data-testid="dataGridCustomRow">

        {
          showTopTitle ? <Grid container item sm={12} direction='row' justifyContent="space-between">
            <Grid item sm={8}>
              <DataGridField
                header=""
                contentStyles={{
                  whiteSpace: 'normal',
                }}
              >
                <DataGridDocumentLink path={path}>{documentName}</DataGridDocumentLink>
              </DataGridField> 
            </Grid>
            {rightTopComponent ? 
              <Grid 
                item 
                sm={4} 
                container
                direction="row"
                justifyContent="flex-end"
                >
                  <Grid item>
                    {rightTopComponent}
                  </Grid>
              </Grid> : null}
          </Grid> : null
        }

        <Grid container item direction='row' sm={12}>
          {
            fields?.map(( { children, name, size }: any, idx: number ) => ( 
              name === 'menuOptions' ? 
                <Grid 
                  item
                  container
                  direction="row"
                  justifyContent="flex-end"
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${Date.now()}-${logName}-${idx}`}
                  sm={size}
                >
                  {children}
                </Grid>
              : 
                <Grid 
                  item
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${Date.now()}-${logName}-${idx}`}
                  sm={size}
                >
                  {children}
                </Grid>
              )
            )
          }
        </Grid>
              
    </Grid>
  ));

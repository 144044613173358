import { IAttachmentLandingZoneState } from '@esub-engineering/react-component-library';
import { IUserTimeCard, ITimeEntry } from '../../timeCardManagement/types';
import { ICreateTimeCardInput } from '../../timeCardManagement/components/MultiTimeEntryForm/types';
import { IDailyReport } from '../types';

export const SET_DAILY_REPORT = 'SET_DAILY_REPORT';
export const RESET_DAILY_REPORT = 'RESET_DAILY_REPORT';
export const SET_PENDING_DAILY_REPORT_ATTACHMENTS = 'SET_PENDING_DAILY_REPORT_ATTACHMENTS';
export const RESET_PENDING_DAILY_REPORT_ATTACHMENTS = 'RESET_PENDING_DAILY_REPORT_ATTACHMENTS';
export const SET_CURRENT_TIME_ENTRIES = 'SET_CURRENT_TIME_ENTRIES'
export const RESET_CURRENT_TIME_ENTRIES = 'RESET_CURRENT_TIME_ENTRIES'
export const SET_TIME_ENTRIES_PENDING_CHANGES = 'SET_TIME_ENTRIES_PENDING_CHANGES';
export const RESET_TIME_ENTRIES_PENDING_CHANGES = 'RESET_TIME_ENTRIES_PENDING_CHANGES';
export const SET_TIME_ENTRIES_TO_DELETE = 'SET_TIME_ENTRIES_TO_DELETE';
export const RESET_TIME_ENTRIES_TO_DELETE = 'RESET_TIME_ENTRIES_TO_DELETE';
export const SET_TIME_ENTRIES_TO_CREATE = 'SET_TIME_ENTRIES_TO_CREATE';
export const DELETE_TIME_ENTRIES_TO_CREATE = 'DELETE_TIME_ENTRIES_TO_CREATE';
export const RESET_TIME_ENTRIES_TO_CREATE = 'RESET_TIME_ENTRIES_TO_CREATE';
export const ARE_NEW_TIME_ENTRIES_ADDED = 'ARE_NEW_TIME_ENTRIES_ADDED';

export const setDailyReport = (report: IDailyReport) => ({
  type: SET_DAILY_REPORT,
  payload: report,
});

export const resetDailyReport = () => ({
  type: RESET_DAILY_REPORT,
});

export const setPendingDailyReportAttachments = (
  attachments: IAttachmentLandingZoneState<any, any>
) => ({
  type: SET_PENDING_DAILY_REPORT_ATTACHMENTS,
  payload: attachments,
});

export const resetPendingDailyReportAttachments = () => ({
  type: RESET_PENDING_DAILY_REPORT_ATTACHMENTS,
});

export const setCurrentTimeEntries = (entries: ITimeEntry[]) => ({
  type: SET_CURRENT_TIME_ENTRIES,
  payload: entries
})

export const resetCurrentTimeEntries = () => ({
  type: RESET_CURRENT_TIME_ENTRIES
})

export const setTimeEntriesPendingChanges = (entry: IUserTimeCard) => ({
  type: SET_TIME_ENTRIES_PENDING_CHANGES,
  payload: entry,
});

export const resetTimeEntriesPendingChanges = () => ({
  type: RESET_TIME_ENTRIES_PENDING_CHANGES,
});

export const setCrewTimeEntriesToDelete = (id: string) => ({
  type: SET_TIME_ENTRIES_TO_DELETE,
  payload: id,
});

export const resetCrewTimeEntriesToDelete = () => ({
  type: RESET_TIME_ENTRIES_TO_DELETE,
});

export const setCrewTimeEntriesToCreate = (timeCard: ICreateTimeCardInput[]) => ({
  type: SET_TIME_ENTRIES_TO_CREATE,
  payload: timeCard,
});

export const deleteCrewTimeEntriesToCreate = (id: string) => ({
  type: DELETE_TIME_ENTRIES_TO_CREATE,
  payload: id,
});

export const resetCrewTimeEntriesToCreate = () => ({
  type: RESET_TIME_ENTRIES_TO_CREATE,
});

export const areNewTimeEntriesAdded = (value: boolean) => ({
  type: ARE_NEW_TIME_ENTRIES_ADDED,
  payload: value,
});

// FIXME: these routes do not follow the rest of the app guidelines
// TODO: enforce /path/:action/:id

export const routes = Object.freeze({
  addCrewCreateMode: '/projects/:projectId/daily-reports/time-management/add-time',
  addCrewEditMode: '/projects/:projectId/daily-reports/:dailyReportId/time-management/add-time',
  addCrewCopyMode:
    '/projects/:projectId/daily-reports/:dailyReportId/time-management/add-time/copy',
});

export const paths = [
  '/projects/:projectId/daily-reports',
  '/projects/:projectId/daily-reports/:action',
  '/projects/:projectId/daily-reports/:action/:defaultTab',
  '/projects/:projectId/daily-reports/:action/:dailyReportId',
  '/projects/:projectId/daily-reports/:dailyReportId/:action/:defaultTab',
  ...Object.values(routes),
];

import React from 'react';
import { LinearProgress } from '@material-ui/core';
import { useStyles } from './styles';

type ProgressBarProps = {
  percentComplete: number;
};

export const ProgressBar = React.memo<ProgressBarProps>(({ percentComplete }) => {
  const classes = useStyles();

  return <LinearProgress variant="determinate" value={percentComplete} classes={classes} />;
});

import { projectQueries } from '@esub-engineering/react-common-lib';
import { useCachedGraphQuery, UseCachedGraphQueryOptions } from '../useCachedGraphQuery';

type UseGetProjectTemplateRateReturn = {
  getProject: {
    id: string;
    projectRateTemplateId: string;
  };
};

type UseGetProjectTemplateRateInput = {
  projectId: string;
};

interface UseGetProjectTemplateRateBaseProps<TData>
  extends UseCachedGraphQueryOptions<UseGetProjectTemplateRateReturn, TData>,
    UseGetProjectTemplateRateInput {}

type UseGetProjectTemplateRateProps<TData> = Omit<
  UseGetProjectTemplateRateBaseProps<TData>,
  'key' | 'gql'
>;

export const useGetProjectTemplateRate = ({
  projectId,
  ...rest
}: UseGetProjectTemplateRateProps<UseGetProjectTemplateRateReturn>) =>
  useCachedGraphQuery<
    UseGetProjectTemplateRateReturn,
    UseGetProjectTemplateRateReturn,
    UseGetProjectTemplateRateInput
  >({
    ...rest,
    enabled: Boolean(projectId),
    key: [projectQueries.projectTemplateRateKey, projectId],
    gql: projectQueries.getProjectTemplateRateId,
    variables: {
      id: projectId,
    },
  });

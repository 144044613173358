import React, { useCallback, useMemo } from 'react';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { useCurrentUser } from '../../../auth/hooks';
import { getEnvironment } from '../../utils';
import {
  getLDEnvironmentKey,
  ESUB_LD_USER_KEY,
  ESUB_LD_PREPROD_EMAIL,
  ESUB_LD_PREPROD_FIRSTNAME,
  ESUB_LD_PREPROD_LASTNAME,
  ESUB_LD_PREPROD_ORGID,
  ESUB_LD_PREPROD_COMPANYID,
} from '../../config';

type Props = {
  children: React.ReactNode | React.ReactChild | React.ReactChildren;
};

const ldEnvironmentKey = getLDEnvironmentKey(window.location.host);

const ChildComponent = ({ children }: Props) => <>{children}</>;

export const LaunchDarklyProvider = React.memo(({ children }: Props): any => {
  const { currentUser } = useCurrentUser();
  const environment = getEnvironment({ skipNodeEnv: false });
  const user = useMemo(() => currentUser?.data, [currentUser?.data]);
  const isProdUser = environment === 'production' || environment === 'demo' || environment === 'dr';

  const Component = useCallback(() => <ChildComponent>{children}</ChildComponent>, [children]);

  if (!user) {
    return null;
  }

  const LDProvider = withLDProvider({
    clientSideID: ldEnvironmentKey,
    user: {
      kind: 'user',
      key: isProdUser ? user?.id : ESUB_LD_USER_KEY,
      email: isProdUser ? user?.email : ESUB_LD_PREPROD_EMAIL,
      firstName: isProdUser ? user?.givenName : ESUB_LD_PREPROD_FIRSTNAME,
      lastName: isProdUser ? user?.familyName : ESUB_LD_PREPROD_LASTNAME,
      custom: {
        orgId: isProdUser ? user?.orgId : ESUB_LD_PREPROD_ORGID,
        companyId: isProdUser ? user?.defaultCompany! : ESUB_LD_PREPROD_COMPANYID,
      },
    },
    options: {
      bootstrap: 'localStorage',
    },
    reactOptions: {
      useCamelCaseFlagKeys: true,
    },
  })(Component);

  return <LDProvider />;
});

import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import {
  ActivateAccountPage,
  ConfirmationPasswordSetPage,
  RequestVerificationCodePage,
  VerificationAndPasswordSetPage,
  LoginPage,
  InitCurrentUser,
} from '../auth/containers';
import { LaunchDarklyProvider, I18n } from '../common/containers';
import { NotFoundPage, UnauthorizedPage } from '../common/components';
import { SyncToken } from './containers/SyncToken';

import { paths as userAdminPaths } from './UserAdmin/paths';
import { paths as resourcesPaths } from './Resources/paths';
import { paths as settingsPaths } from './Settings/paths';
import { paths as projectsPaths } from './Projects/paths';
import { paths as drivePaths } from './Drive/paths';
import { paths as timeManagementPaths } from './TimeManagement/paths';
import { paths as documentsPaths } from './Documents/paths';
import { paths as integrationsPaths } from './Integrations/paths';
import { AsyncCenterProvider } from '../common/providers/AsyncCenterProvider';
import { AsyncCenter } from '../common/containers/AsyncCenter';
import { InitOrganization } from '../common/containers/InitOrganization';
import { RouteErrorBoundary } from './components/RouteErrorBoundary';

const UserAdminRoutes = React.lazy(() =>
  import('./UserAdmin').then((module) => ({ default: module.UserAdminRoutes }))
);
const ResourcesRoutes = React.lazy(() =>
  import('./Resources').then((module) => ({ default: module.ResourcesRoutes }))
);
const SettingsRoutes = React.lazy(() =>
  import('./Settings').then((module) => ({ default: module.SettingsRoutes }))
);
const ProjectsRoutes = React.lazy(() =>
  import('./Projects').then((module) => ({ default: module.ProjectsRoutes }))
);
const TimeManagementRoutes = React.lazy(() =>
  import('./TimeManagement').then((module) => ({ default: module.TimeManagementRoutes }))
);
const DriveRoutes = React.lazy(() =>
  import('./Drive').then((module) => ({ default: module.DriveRoutes }))
);

const DocumentsRoutes = React.lazy(() =>
  import('./Documents').then((module) => ({ default: module.DocumentsRoutes }))
);

const IntegrationsRoutes = React.lazy(() =>
  import('./Integrations').then((module) => ({ default: module.IntegrationsRoutes }))
);

export const Routes = () => (
  <RouteErrorBoundary>
    <BrowserRouter>
      <Switch>
        <Route exact path="/">
          <Redirect to="/projects" />
        </Route>

        <Route exact path="/login">
          <LoginPage />
        </Route>

        <Route exact path="/activate">
          <ActivateAccountPage />
        </Route>

        <Route exact path="/forgotten-password">
          <RequestVerificationCodePage />
        </Route>

        <Route exact path="/password-confirmed">
          <ConfirmationPasswordSetPage />
        </Route>

        <Route exact path="/set-password">
          <VerificationAndPasswordSetPage />
        </Route>

        <Route exact path="/unauthorized">
          <UnauthorizedPage />
        </Route>

        <Route
          path={[
            ...userAdminPaths,
            ...timeManagementPaths,
            ...resourcesPaths,
            ...settingsPaths,
            ...projectsPaths,
            ...drivePaths,
            ...documentsPaths,
            ...integrationsPaths,
          ]}
        >
          {/* TODO: replace with useCurrentUserHook */}
          <SyncToken>
            <InitCurrentUser>
              <InitOrganization>
                <LaunchDarklyProvider>
                  <I18n>
                    <AsyncCenterProvider>
                      <AsyncCenter />
                      <Switch>
                        <Route path={timeManagementPaths}>
                          <React.Suspense fallback="">
                            <TimeManagementRoutes />
                          </React.Suspense>
                        </Route>
                        <Route path={resourcesPaths}>
                          <React.Suspense fallback="">
                            <ResourcesRoutes />
                          </React.Suspense>
                        </Route>
                        <Route path={userAdminPaths}>
                          <React.Suspense fallback="">
                            <UserAdminRoutes />
                          </React.Suspense>
                        </Route>
                        <Route path={settingsPaths}>
                          <React.Suspense fallback="">
                            <SettingsRoutes />
                          </React.Suspense>
                        </Route>
                        <Route path={projectsPaths}>
                          <React.Suspense fallback="">
                            <ProjectsRoutes />
                          </React.Suspense>
                        </Route>
                        <Route path={drivePaths}>
                          <React.Suspense fallback="">
                            <DriveRoutes />
                          </React.Suspense>
                        </Route>
                        <Route path={documentsPaths}>
                          <React.Suspense fallback="">
                            <DocumentsRoutes />
                          </React.Suspense>
                        </Route>
                        <Route path={integrationsPaths}>
                          <React.Suspense fallback="">
                            <IntegrationsRoutes />
                          </React.Suspense>
                        </Route>
                      </Switch>
                    </AsyncCenterProvider>
                  </I18n>
                </LaunchDarklyProvider>
              </InitOrganization>
            </InitCurrentUser>
          </SyncToken>
        </Route>

        <Route path="*">
          <NotFoundPage />
        </Route>
      </Switch>
    </BrowserRouter>
  </RouteErrorBoundary>
);

export default Routes;

import { TEntityAssignment } from '../../types';

export const initialState: Array<TEntityAssignment> = [];

// eslint-disable-next-line @typescript-eslint/default-param-last
export default (state = initialState, { type, payload }: any) => {
  switch (type) {
    case 'CLEAR_ENTITY_ASSIGNMENTS':
      return [];
    case 'SET_ENTITY_ASSIGNMENTS':
      return [...payload];
    default:
      return state;
  }
};

import { gql } from 'graphql-request';

export const ATTACHMENT_FRAGMENT = gql`
  fragment AttachmentFields on Attachment {
    id
    key
    link
    algorithm
    credential
    date
    token
    policy
    signature
  }
`;

export const DOCUMENT_GROUP_FRAGMENT = gql`
  fragment DocumentGroupFields on DocGroup {
    id
    link
    type
    name
  }
`;

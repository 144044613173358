import { ReduxAction } from '../types';
import { ToastType, ToastActionPayloadType } from './types';

export const setToast: ReduxAction<ToastType, ToastActionPayloadType> = (payload) => ({
  type: 'SET_TOAST',
  payload,
});

export const openToast: ReduxAction<ToastType, ToastActionPayloadType> = (payload) => ({
  type: 'OPEN_TOAST',
  payload,
});

export const closeToast: ReduxAction<undefined, ToastActionPayloadType> = () => ({
  type: 'CLOSE_TOAST',
});

import { useMemo } from 'react';
import { useFlags } from '../useFlags';

/**
 * Hook to provide custom esub indexing headers
 * @description setting x-esub-waitfor header on gql requests
 * will enable platform to wait until search indexing is
 * complete before issuing mutation response.
 */
export const useEsubWaitForIndexingHeaders = () => {
  const { flags } = useFlags();

  const waitForFlagEnabled = (flags?.submittalsWaitforIndexing as boolean) || false;

  const requestHeaders = useMemo(
    () =>
      waitForFlagEnabled
        ? {
            'x-esub-waitfor': 'indexing',
          }
        : ({} as any),
    [waitForFlagEnabled]
  );

  return {
    requestHeaders,
  };
};

import * as actions from './actions';
import { ProgressIndicatorState, ProgressIndicatorPayload } from './types';

const initialState: ProgressIndicatorState = {
  percentComplete: 0,
  entity: 'DRIVE', // default to drive
  status: '',
  customDescription: undefined,
  open: false,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export default (state = initialState, { type, payload }: ProgressIndicatorPayload) => {
  switch (type) {
    case actions.SET_PROGRESS:
      return {
        ...state,
        ...payload,
      };
    case actions.OPEN_PROGRESS:
      return {
        ...state,
        ...payload,
        open: true,
      };
    case actions.CLOSE_PROGRESS:
    case actions.RESET_PROGRESS:
      return initialState;
    default:
      return state;
  }
};

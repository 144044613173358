import { paths as companySettingsPaths } from '../CompanySettings/paths';
import { paths as companyDetailsPaths } from '../CompanyDetails/paths';
import { paths as costCodesPaths } from '../CostCodes/paths';
import { paths as timecardPreferencesPaths } from '../TimecardPreferences/paths';
import { paths as keyWordsPaths } from '../Keywords/paths';
import { paths as generalPreferencesPaths } from '../GeneralPreferences/paths';
import { paths as dailyReportsPaths } from '../ReportsPreferences/paths';
import { paths as laborRatePaths } from '../LaborRates/paths';
import { paths as laborClassPaths } from '../LaborClasses/paths';
import { paths as requestInformationPaths } from '../RequestInformation/paths';
import { paths as changeOrderRequestsPaths } from '../ChangeOrderRequests/paths';
import { paths as unitsOfMeasurePaths } from '../UnitsOfMeasure/paths';
import { paths as purchaseOrderPaths } from '../PurchaseOrder/paths';
import { paths as drawingsPaths } from '../Drawings/paths';

export const paths = [
  ...companySettingsPaths,
  ...companyDetailsPaths,
  ...costCodesPaths,
  ...timecardPreferencesPaths,
  ...keyWordsPaths,
  ...generalPreferencesPaths,
  ...dailyReportsPaths,
  ...laborRatePaths,
  ...laborClassPaths,
  ...requestInformationPaths,
  ...changeOrderRequestsPaths,
  ...unitsOfMeasurePaths,
  ...purchaseOrderPaths,
  ...drawingsPaths,
];

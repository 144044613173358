import React from 'react';
import ReactDOM from 'react-dom';
import { datadogRum } from '@datadog/browser-rum';
import { getStageFromLocation } from '@esub-engineering/react-common-lib';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './polyfills';
import './index.css';
import { ZendeskWidget } from './common/components';

const { NODE_ENV } = process.env;
const stage = getStageFromLocation(window.location.hostname);

if (stage !== 'branch' && NODE_ENV === 'production') {
  console.log('debug init DDRUM');
  datadogRum.init({
    applicationId: 'e115cc1f-41a5-4889-b2ce-d602d2f54953',
    clientToken: 'pub2d8acc497129b03bc32eab37ef212d0b',
    site: 'datadoghq.com',
    service: 'esub-cloud-customer-web-app',
    env: stage,
    // Specify a version number to identify the deployed version of your application in Datadog
    version: process.env.REACT_APP_APPLICATION_VERSION,
    sampleRate: 100,
    replaySampleRate: 100,
    defaultPrivacyLevel: 'allow',
    // @ts-ignore
    trackResources: true,
    trackUserInteractions: true,
    trackFrustrations: true,
    allowedTracingOrigins: [/https:\/\/.*\.esubcloud\.com/],
  });
}

// enable datadog session replays for desired environments
// @ts-ignore
if ((stage === 'qa' || stage === 'prod' || stage === 'demo') && Boolean(window?.DD_RUM)) {
  // eslint-disable-next-line no-console
  console.log('DD session replays enabled');
  datadogRum.startSessionReplayRecording();
}

ReactDOM.render(
  <React.StrictMode>
    <App />
    <ZendeskWidget />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React from 'react';
import { Container, Paper, Box } from '@material-ui/core';
import { UserLogin } from '@esub-engineering/common-containers';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { Page } from '@esub-engineering/react-component-library';
import { openToast } from '../../../common/redux/toast';
import { EmptyPageWrapper } from '../../../common/containers';
import useStyles from './styles';

export const LoginPage = React.memo(() => {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const year = new Date().getFullYear();

  const handleLogin = () => {
    history.push('/projects');
  };

  return (
    <EmptyPageWrapper>
      <Page>
        <Container fixed maxWidth="sm">
          <br />
          <Paper>
            <Box p={2}>
              <UserLogin
                subtitle="to continue to eSUB Cloud"
                onSuccess={handleLogin}
                onError={() =>
                  dispatch(
                    openToast({
                      type: 'error',
                      message: 'Unable to login',
                    })
                  )
                }
              />
            </Box>
          </Paper>
        </Container>
        <Container maxWidth="md">
          <Box className={classes.termsAndConditions}>
            By using eSUB, you agree to the{' '}
            <a
              href="https://www.esubonline.com/TRACKpoint/global/gblTerms.asp"
              target="_blank"
              rel="noreferrer"
            >
              Terms and Conditions
            </a>{' '}
            and the{' '}
            <a href="https://app.esub.com/apiterms" target="_blank" rel="noreferrer">
              API Terms of Use
            </a>{' '}
            as set forth by{' '}
            <a href="https://esub.com/" target="_blank" rel="noreferrer">
              eSUB, Inc.
            </a>
            <br />
            <br />@{year} eSUB, Inc. All Rights Reserved.
          </Box>
        </Container>
      </Page>
    </EmptyPageWrapper>
  );
});

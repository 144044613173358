import { DrawingsSnapshotJobStatus, SheetsOcrJobStatus } from '@esub-engineering/platform-types';
import { useEffect } from 'react';
import { useGetSheetsOcrJob } from '../../../drawings/hooks/useGetSheetsOCRJob';
import { useGetVersionSetPdfJob } from '../../../drawings/hooks/useGetVersionSetPdfJob';
import { VersionSetPdfJobStatus } from '../../../drawings/hooks/useGetVersionSetPdfJob/types';
import { useGetSnapshotJob } from '../../../snapshots/hooks/useGetSnapshotJob';
import { useAsyncCenterActions, useAsyncCenterStore } from '../../providers/AsyncCenterProvider';
import { useToast } from '../useToast';

export const useAsyncCenter = () => {
  const store = useAsyncCenterStore();
  const actions = useAsyncCenterActions();
  const { openErrorToast, openSuccessToast } = useToast();

  useEffect(() => {
    actions.resetAsyncStore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useGetVersionSetPdfJob({
    id: store.getVersionSetPdfJobId,
    projectId: store.projectId,
    enabled: Boolean(store.projectId) && Boolean(store.getVersionSetPdfJobId),
    onSuccess: (d) => {
      if (d.status === VersionSetPdfJobStatus.SUCCEEDED) {
        actions.setPdfJob({
          getVersionSetPdfJobStatus: VersionSetPdfJobStatus.SUCCEEDED,
          getVersionSetPdfJobId: store.getVersionSetPdfJobId,
          projectId: '',
        });
      } else {
        actions.setPdfJob({
          getVersionSetPdfJobStatus: VersionSetPdfJobStatus.FAILED,
          getVersionSetPdfJobId: '',
          projectId: '',
        });
        openErrorToast(d.error || 'Failed to create version set.');
      }
    },
  });

  useGetSheetsOcrJob({
    id: store.getSheetsOcrJobId,
    projectId: store.projectId,
    enabled: Boolean(store.projectId) && Boolean(store.getSheetsOcrJobId),
    onSuccess: (d) => {
      if (d.status === SheetsOcrJobStatus.Succeeded) {
        actions.setOcrJob({
          getSheetsOcrJobStatus: SheetsOcrJobStatus.Succeeded,
          getSheetsOcrJobId: '',
          projectId: '',
          versionSetId: '',
        });
      } else {
        actions.setOcrJob({
          getSheetsOcrJobStatus: SheetsOcrJobStatus.Failed,
          getSheetsOcrJobId: '',
          projectId: '',
          versionSetId: '',
        });
      }
    },
  });

  useGetSnapshotJob({
    id: store.getSnapshotJobId,
    projectId: store.projectId,
    enabled: store.getSnapshotJobId !== '' || Boolean(store.getSnapshotJobId),
    onSuccess: (d) => {
      if (d.status === DrawingsSnapshotJobStatus.Succeeded) {
        actions.setSnapshotJob({
          getSnapshotJobStatus: DrawingsSnapshotJobStatus.Succeeded,
          getSnapshotJobId: '',
          snapshotId: d.snapshotId!,
          projectId: '',
        });
        openSuccessToast('Snapshot created successfully.');
      } else {
        actions.setSnapshotJob({
          getSnapshotJobStatus: DrawingsSnapshotJobStatus.Failed,
          getSnapshotJobId: '',
          snapshotId: undefined,
          projectId: '',
        });
        openErrorToast('Failed to create snapshot.');
      }
    },
  });
};

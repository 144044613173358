import { ItemReturn } from '@esub-engineering/react-common-lib';
import { IUseGraphQueryProps } from '../../../common/hooks';

export enum VersionSetPdfJobStatus {
  UPLOAD_PENDING = 'UPLOAD_PENDING',
  FILE_UPLOADED = 'FILE_UPLOADED',
  FILE_SPLIT = 'FILE_SPLIT',
  SUCCEEDED = 'SUCCEEDED',
  FAILED = 'FAILED',
}

export type TData = {
  created: Date;
  id: String;
  modified: String;
  status: VersionSetPdfJobStatus;
  error: string;
};

export type Return = ItemReturn<'getVersionSetPdfJob', TData>;

export type Input = { id: string; projectId: string };

export type GraphProps = IUseGraphQueryProps<Return, Error, TData, any>;

export type UseGetVersionSetPdfJobProps = Omit<GraphProps, 'gql' | 'key'> & {
  id: string;
  projectId: string;
};

import React, { useState, memo, useCallback } from 'react';
import { Table } from '@esub-engineering/react-component-library';
import Papa from 'papaparse';
import { FileImport } from '../../components/FileImport';

export type TUseImportFromCSV = {
  data: any[];
  FileImport: React.FunctionComponent<any>;
  ImportTable: React.FunctionComponent<any>;
  selectedFile: File | undefined;
  isLoading: boolean;
};

type UseImportFromCSVProps = {
  header?: boolean;
  skipEmptyLines?: boolean;
  transformHeader?: (header: string) => string | void;
};

const defaultOptions = {
  header: false,
  skipEmptyLines: false,
  transformHeader: () => {},
};

export const useImportFromCSV = ({
  header,
  skipEmptyLines,
  transformHeader,
}: UseImportFromCSVProps = defaultOptions): TUseImportFromCSV => {
  const fileTypes = ['.csv'];
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<File>();
  const [parsedCSVData, setParsedCSVData] = useState<any[]>([]);

  const handleOnChange = useCallback(
    (file: File) => {
      setIsLoading(true);

      // @ts-ignore
      Papa.parse(file, {
        header, // use first row from csv as column/field names
        skipEmptyLines,
        transformHeader,
        complete(results) {
          if (!header) {
            // handle new line inserted by programs when saving csv.
            const newLineIndex = results.data.findIndex((lineItem: any) =>
              lineItem.every((columnItem: any) => columnItem === '')
            );

            if (newLineIndex > -1) {
              results.data.splice(newLineIndex, 1);
            }
          }

          setParsedCSVData(results.data);
          setIsLoading(false);
        },
      });
      setSelectedFile(file);
    },
    [header, skipEmptyLines, transformHeader]
  );

  const FileImportComponent = memo(() => (
    <FileImport onChange={handleOnChange} acceptedFileTypes={fileTypes.join(',')} />
  ));

  const ImportTable = memo(({ columns }: { columns: any[] }) => (
    <Table
      isLoading={false}
      columns={columns}
      data={parsedCSVData.slice(1)}
      emptyDataText="No items available."
      edit={!!parsedCSVData.length}
    />
  ));

  return {
    FileImport: FileImportComponent,
    ImportTable,
    data: parsedCSVData,
    selectedFile,
    isLoading,
  };
};

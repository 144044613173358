import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Toast as ToastComponent } from '@esub-engineering/react-component-library';
import { closeToast, ToastType, openToast } from '../../redux/toast';

export const useToast = () => {
  const dispatch = useDispatch();

  const { open, message, autoHideDuration, type } = useSelector<{ toast: ToastType }, ToastType>(
    (state) => state.toast
  );

  const openSuccessToast = (msg: string) => {
    dispatch(
      openToast({
        message: msg,
        type: 'success',
      })
    );
  };

  const openErrorToast = (msg: string) => {
    dispatch(
      openToast({
        message: msg,
        type: 'error',
      })
    );
  };

  const Toast = (
    <ToastComponent
      open={open}
      type={type}
      autoHideDuration={autoHideDuration}
      message={message}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      onClose={() => dispatch(closeToast())}
    />
  );

  return {
    Toast,
    closeToast,
    openErrorToast,
    openSuccessToast,
  };
};

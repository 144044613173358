import { ITablesFilterState, TTableFilterActionPayload } from './types';

const initialState: Partial<ITablesFilterState> = {};

// eslint-disable-next-line @typescript-eslint/default-param-last
export default (state = initialState, { type, payload }: TTableFilterActionPayload) => {
  switch (type) {
    case 'SET_FILTERS':
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};

import { TCurrentUser, TCurrentUserPayload } from './types';

export const initialState: TCurrentUser = {
  permissions: [],
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export default (state = initialState, { type, payload }: TCurrentUserPayload) => {
  switch (type) {
    case 'SET_USER':
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};

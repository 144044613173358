import { Reducer } from "react";
import makeStore, { Action } from "../../utils/SuperProvider";
import { AsyncCenterState, AsyncCenterTypes } from "./types";


const initialState:AsyncCenterState = {
  getVersionSetPdfJobId: '',
  getVersionSetPdfJobStatus: undefined,
  getSheetsOcrJobId: '',
  getSheetsOcrJobStatus: undefined,
  getSnapshotJobId: '',
  getSnapshotJobStatus: undefined,
  snapshotId: undefined,
  projectId: '',
}

const asyncCenterReducer:Reducer<AsyncCenterState, Action<AsyncCenterTypes>> = (state, { type, payload }) => {
  switch (type) {
    case AsyncCenterTypes.setPdfJob:
        return {
          ...state,
          ...payload
        }
    case AsyncCenterTypes.setOcrJob:
        return {
          ...state,
          ...payload
        }
    case AsyncCenterTypes.setSnapshotJob:
        return {
          ...state,
          ...payload
        }
    case AsyncCenterTypes.reset:
      return {
        ...initialState,
      }
    default:
      return state
  }
}

const {
  StoreProvider: AsyncCenterProvider, 
  useDispatch: useAsyncCenterDispatch,
  useStore: useAsyncCenterStore, 
} = makeStore<AsyncCenterState, AsyncCenterTypes>(asyncCenterReducer, initialState, 'async')

const useAsyncCenterActions = () => {
  const dispatch = useAsyncCenterDispatch()

  return {
    setPdfJob: (payload:{
      getVersionSetPdfJobId: string;
      getVersionSetPdfJobStatus?: string;
      projectId: string;
    }) => dispatch({
      type: AsyncCenterTypes.setPdfJob,
      payload
    }),

    setOcrJob: (payload:{
      getSheetsOcrJobId: string;
      getSheetsOcrJobStatus?: string;
      projectId: string;
      versionSetId: string;
    }) => dispatch({
      type: AsyncCenterTypes.setOcrJob,
      payload
    }),
    
    setSnapshotJob: (payload:{
      getSnapshotJobId: string;
      getSnapshotJobStatus?: string;
      snapshotId?: string;
      projectId: string;
    }) => dispatch({
      type: AsyncCenterTypes.setSnapshotJob,
      payload
    }),

    resetAsyncStore: () => dispatch({
      type: AsyncCenterTypes.reset,
      payload: true
    })
  }
}

export { AsyncCenterProvider, useAsyncCenterStore, useAsyncCenterActions }

import React, { useState, useMemo, useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';
import { NotificationCard } from '@esub-engineering/react-component-library';
import { UseFreshEntityState } from './types';

const NC_STORAGE_KEY = 'ncFreshEntity';

/**
 * Redirect to pages within the application and conditionally
 * display a notification component based on the location state
 */
export const useFreshEntity = <OriginalType extends object>() => {
  const queryClient = useQueryClient();
  const history = useHistory();
  const location = useLocation<{ freshEntity: UseFreshEntityState<OriginalType> }>();

  const resetStorageKey = () => localStorage.removeItem(NC_STORAGE_KEY);

  const invalidateQuery = useCallback(
    (key) => {
      queryClient.invalidateQueries(key);
      // close notification after refreshing list
      resetStorageKey();
    },
    [queryClient]
  );

  const viewEntity = useCallback(
    (entityUri) => {
      resetStorageKey();
      history.push(entityUri);
    },
    [history]
  );

  /**
   * Freshly created entity from state
   */
  const freshEntity: UseFreshEntityState<OriginalType> | undefined = useMemo(
    () => location.state?.freshEntity,
    [location]
  );

  /**
   * Constructs freshEntity state with given params, then push history changes
   */
  const redirectWithState = useCallback(
    ({
      key,
      uri,
      original,
      entityUri,
      entityName,
      error,
      customMessage,
      customPrimaryBtnText,
      customSecondaryBtnText,
      state = {},
    }: {
      key: string;
      uri: string;
      customMessage?: string;
      customPrimaryBtnText?: string;
      customSecondaryBtnText?: string;
      state?: any;
    } & Omit<UseFreshEntityState<OriginalType>, 'message' | 'buttonText'>) => {
      const message = customMessage || `${entityName} successfully created.`;
      const primaryButtonText = customPrimaryBtnText || 'Refresh List';
      const secondaryButtonText = customSecondaryBtnText || `View ${entityName}`;

      const freshEntityState: UseFreshEntityState<OriginalType> = {
        key,
        error,
        entityUri,
        entityName,
        message,
        primaryButtonText,
        secondaryButtonText,
        original,
      };

      localStorage.setItem(NC_STORAGE_KEY, JSON.stringify({ key, active: true }));

      history.push(uri, { freshEntity: freshEntityState, ...state });
    },
    [history]
  );

  /**
   * Notification component with built in state, only visible if freshEntity is in location state
   */
  const Notification = React.memo(() => {
    const [notificationVisible, setNotificationVisible] = useState(Boolean(freshEntity));

    const closeNotification = useCallback(() => {
      resetStorageKey();
      setNotificationVisible(false);
    }, []);

    const handlePrimaryClick = useCallback(
      (key: string) => {
        invalidateQuery(key);
        closeNotification();
      },
      [closeNotification]
    );

    if (!freshEntity || freshEntity?.error || !localStorage.getItem(NC_STORAGE_KEY)) {
      return null;
    }

    const { key, message, entityUri, primaryButtonText, secondaryButtonText } = freshEntity;

    return (
      <NotificationCard
        open={notificationVisible}
        message={message}
        primaryButtonText={primaryButtonText}
        secondaryButtonText={secondaryButtonText}
        onClose={closeNotification}
        onClickSecondary={entityUri ? () => viewEntity(entityUri) : null}
        onClickPrimary={() => handlePrimaryClick(key)}
      />
    );
  });

  return {
    redirectWithState,
    Notification,
    freshEntity,
  };
};

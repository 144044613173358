import React, { useMemo } from 'react';
import { Grid } from '@material-ui/core';
import useStyles from './styles';
import { DataGridFieldProps } from '../../types';

export const DataGridField = React.memo<DataGridFieldProps>(
  ({ header = '', value, children, dataTestId, headerStyles = {}, contentStyles = {}, ...rest }) => {
    const styles = useStyles();

    // cause Chad asked nicely :)
    const testId = useMemo(() => {
      const val = dataTestId || header || 'unknown';

      return `grid-${val.toLowerCase().replaceAll(' ', '')}-col`;
    }, [header, dataTestId]);

    return (
      <Grid container spacing={2} data-testid={testId} {...rest}>
        {header.length !== 0 ? <Grid item xs={12} style={{ ...headerStyles, paddingBottom: '0' }}>
          <div className={styles.box}>
            <span className={styles.header}>{header}</span>
          </div>
        </Grid> : null}
        <Grid item xs={12} className={styles.content} style={contentStyles}>
          <div className={styles.box}>
            {children ? (
              <div className={styles.childrenContainer}>{children}</div>
            ) : (
              <span className={styles.value}>{value || ''}</span>
            )}
          </div>
        </Grid>
      </Grid>
    );
  }
);

import {
  SET_COMPANY_COST_CODES,
  SET_PROJECT_COST_CODES,
  CLEAR_COMPANY_COST_CODES,
  CLEAR_PROJECT_COST_CODES,
} from './actions';
import { ICostCode, IProjectCostCode } from '../../types';

type CostCodeState = {
  company: ICostCode[];
  project: IProjectCostCode[];
};

export const initialState: CostCodeState = {
  company: [],
  project: [],
};

export default (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state = initialState,
  { type, payload }: { type: string; payload: ICostCode[] | IProjectCostCode[] }
) => {
  switch (type) {
    case SET_PROJECT_COST_CODES:
      return {
        company: [...state.company],
        project: payload,
      };
    case CLEAR_PROJECT_COST_CODES:
      return {
        company: [...state.company],
        project: [],
      };
    case SET_COMPANY_COST_CODES:
      return {
        project: [...state.project],
        company: payload,
      };
    case CLEAR_COMPANY_COST_CODES:
      return {
        project: [...state.project],
        company: [],
      };
    default:
      return state;
  }
};

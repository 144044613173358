import { Pagination } from '@esub-engineering/react-common-lib';
import { QueryFunction, QueryKey, useInfiniteQuery, UseInfiniteQueryOptions } from 'react-query';

interface UseInfiniteQueryV2Params<
  TQueryFnData,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
> extends UseInfiniteQueryOptions<TQueryFnData, Error, TData, TQueryFnData, TQueryKey> {
  queryKey: TQueryKey;
  query: QueryFunction<TQueryFnData, TQueryKey>;
}

export interface InfiniteReturn {
  pagination: Pagination;
}

export const useInfiniteQueryV2 = <
  TQueryFnData extends InfiniteReturn,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>({
  queryKey,
  query,
  ...rest
}: UseInfiniteQueryV2Params<TQueryFnData, TData, TQueryKey>) =>
  useInfiniteQuery<TQueryFnData, Error, TData, TQueryKey>(queryKey, query, {
    getNextPageParam: (lastPage) => {
      const { currentPage, pages } = lastPage.pagination;
      if (pages === 0 || currentPage === pages) return undefined;
      return lastPage.pagination.currentPage + 1;
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    queryKey,
    ...rest,
  });

import { DrawingsSnapshotJobStatus } from '@esub-engineering/platform-types';
import { useGraphQuery, useToast } from '../../../common/hooks';
import { getJobId, key } from '../../API/queries';
import { Return, TData, UseGetSnapshotJobProps } from './types';

export const useGetSnapshotJob = ({ id, projectId, ...rest }: UseGetSnapshotJobProps) => {
  const { openErrorToast } = useToast();
  const responseTForm = (d: Return): TData => ({ ...d.getDrawingsSnapshotJob });

  return useGraphQuery<Return, Error, TData, [string, string]>({
    key: [key, id, projectId],
    gql: getJobId,
    enabled: Boolean(id),
    variables: { id, projectId },
    responseTForm,
    onError: () => openErrorToast('Unable to retrieve PDF job info.'),
    validateClientResponseData: (d) => {
      if (d.getDrawingsSnapshotJob.status === DrawingsSnapshotJobStatus.Succeeded) return true;
      if (d.getDrawingsSnapshotJob.status === DrawingsSnapshotJobStatus.Failed) return true;
      return false;
    },
    retry: true,
    retryDelay: 1000,
    ...rest,
  });
};

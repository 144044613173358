import { isSupportedFile } from '@esub-engineering/react-common-lib';
import { TAttachmentLandingZoneFile } from '@esub-engineering/react-component-library';
import { useState } from 'react';
import { DocumentItem } from '../../api';
import { IuseAttachmentFileChipsProps } from './types';

/*
  This Hook encapsulate the logic to use the component <FileChips/> , every time you want to use this component in create or edit forms you
  need to implement this.
 */

export const useAttachmentFileChips = ({
  maxFileSizeMB = 100,
  defaultFiles = [],
  handleFile = () => {},
  orderProperty = false,
}: IuseAttachmentFileChipsProps) => {
  const maxSizeBytes = 1048576 * maxFileSizeMB;
  const [files, setFiles] = useState(defaultFiles);

  // If we start noticing some performance issue with this hook, please revisited this function and try this changes replacing these two .filter calls and the .map with one reduce would make this more performant.
  const getOrderedFiles = (attachedFiles: TAttachmentLandingZoneFile<DocumentItem>[]) => [
    ...attachedFiles
      .filter((file) => !file.removed)
      .map((file, index) => Object.assign(file, { order: index })),
    ...attachedFiles.filter((file) => file.removed),
  ];
  // Handle the files the same as the other file related components located in the component-library
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileObj = event.target.files;
    if (!fileObj) {
      return;
    }
    const fileArray = Array.from(fileObj);

    try {
      const hasBadFiles = Array.from(fileObj).some(
        (file) => file.size > maxSizeBytes && isSupportedFile(file)
      );

      if (hasBadFiles) throw new Error('File(s) were too large or were of an unsupported type.');

      const convertedArray = fileArray as TAttachmentLandingZoneFile<DocumentItem>[];
      const fileUpdate = [...files, ...convertedArray];
      // This is here just in case we want to modify the file chip component to be draggable or if the order is important
      const orderedArray = orderProperty ? getOrderedFiles(fileUpdate) : fileUpdate;

      handleFile(orderedArray);
      setFiles(orderedArray);
    } catch (ex) {
      // eslint-disable-next-line no-console
      console.error('An error occurred while dropping files', ex);
    }
  };

  return {
    files,
    handleFileChange,
    setFiles,
  };
};

import { useState, useCallback } from 'react';
import { useGraphMutation } from '../useGraphMutation';
import {
  TUsePresignedLinkGeneratorProps,
  TUsePresignedLinkGeneratorResult,
  ILinksProps,
  TPresignedLink,
} from './types';

export const usePresignedLinkGenerator = ({
  gql,
  key,
  ...rest
}: TUsePresignedLinkGeneratorProps): TUsePresignedLinkGeneratorResult => {
  const [isLinksLoading, setIsLinksLoading] = useState<boolean>(false);
  const [isLinksError, setIsLinksError] = useState<Error>();

  const { mutateAsync } = useGraphMutation({
    gql,
    key,
    onError: (err) => setIsLinksError(err),
    onSuccess: (data) => data,
    ...rest,
  });

  const getPresignedLinks = useCallback(
    async ({ files, idKeyPair }: ILinksProps): Promise<TPresignedLink[] | Error> => {
      let result: Error | TPresignedLink[];
      try {
        setIsLinksLoading(true);
        result = await Promise.all<TPresignedLink>(
          files.map((file) => mutateAsync({ input: { ...idKeyPair, fileName: file.name } }, {}))
        );
      } catch (err) {
        result = err as Error;
        setIsLinksError(err as Error);
      } finally {
        setIsLinksLoading(false);
      }
      return result;
    },
    [mutateAsync]
  );

  return { getPresignedLinks, isLinksLoading, isLinksError };
};

import { gql } from 'graphql-request';

export const key = 'fieldNotes';

export const listFieldNoteAuthorsSearchFacets = gql`
  query listFieldNoteAuthorsSearchFacets($input: ListFieldNoteAuthorsSearchFacetsInput!) {
    listFieldNoteAuthorsSearchFacets(input: $input) {
      items {
        count
        key
      }
      pagination {
        hits
        currentPage
        pages
        pageSize
      }
    }
  }
`;

export const listFieldNoteKeywordsSearchFacets = gql`
  query listFieldNoteKeywordsSearchFacets($input: ListFieldNoteKeywordsSearchFacetsInput!) {
    listFieldNoteKeywordsSearchFacets(input: $input) {
      items {
        count
        key
      }
      pagination {
        hits
        currentPage
        pages
        pageSize
      }
    }
  }
`;

export const listFieldNoteSearchFacets = gql`
  query listFieldNoteSearchFacets($input: ListFieldNoteSearchFacetsInput!) {
    listFieldNoteSearchFacets(input: $input) {
      authors {
        items {
          count
          key
        }
        pagination {
          hits
          currentPage
          pages
          pageSize
        }
      }
      keywords {
        items {
          count
          key
        }
        pagination {
          hits
          currentPage
          pages
          pageSize
        }
      }
    }
  }
`;

export const searchContacts = gql`
  query searchContacts(
    $direction: ModelSortDirection
    $filter: ContactSearchFilterInput
    $limit: Int
    $page: Int
    $sortBy: ContactSearchSortByColumn
    $term: String
  ) {
    searchContacts(
      limit: $limit
      page: $page
      sortBy: $sortBy
      term: $term
      filter: $filter
      direction: $direction
    ) {
      items {
        document {
          apVendor
          businessName
          businessType
          email
          id
          jobTitle
          name
          notes
          status
        }
        highlight {
          businessName
          businessType
          jobTitle
          name
          notes
        }
      }
      pagination {
        currentPage
        hits
        pageSize
        pages
      }
    }
  }
`;

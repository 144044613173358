import React from 'react';
import { useGraphQLClient } from '@esub-engineering/common-containers';
import { useQuery, useQueryClient } from 'react-query';
import { IUseGraphQueryProps } from './types';

/**
 * Custom use query hook used to pair request with react-query patterns
 * @todo fix types and allow for request variables to be passed as a static type
 */
export const useGraphQuery = <
  TQueryFnData = unknown,
  TError = unknown,
  TData = any,
  TVariables = any
>({
  key,
  gql,
  variables,
  validateClientResponseData,
  responseTForm,
  ...rest
}: IUseGraphQueryProps<TQueryFnData, TError, TData, TVariables>) => {
  const client = useGraphQLClient();
  const queryClient = useQueryClient();

  const request = React.useCallback(async () => {
    const response = await client!.request<any, TVariables>(gql, variables);

    if (validateClientResponseData && !validateClientResponseData(response)) {
      throw new Error('Response is invalid');
    }

    return responseTForm ? responseTForm(response) : response;
  }, [client, gql, validateClientResponseData, variables, responseTForm]);

  // TODO: response type
  return {
    invalidate: () => {
      queryClient.invalidateQueries(key);
    },
    ...useQuery<TQueryFnData, TError, TData>(key, () => request(), {
      refetchOnWindowFocus: false, // TODO: default this at the query client level
      ...rest,
    }),
  };
};

import { useMemo } from 'react';
import {
  useInfiniteQuery as reactQuery,
  QueryFunction,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  InfiniteData,
} from 'react-query';
import { useToast } from '../useToast';

export interface UseInfiniteQuery extends Omit<UseInfiniteQueryResult, 'data'> {
  data: any[];
  rawData?: InfiniteData<any>;
  lastPage?: {
    pagination: {
      pages: string;
      currentPage: number;
      hits: number;
    };
  };
}

/**
 * @description InfiniteQuery hook for paginated list apis (i.e. use nextToken )
 */
export const useInfiniteQueryList = ({
  queryName,
  queryKey,
  query,
  errMessage,
  options = {},
}: {
  queryName: string;
  queryKey: string;
  query: QueryFunction<any, string>;
  errMessage: string;
  options: UseInfiniteQueryOptions<any, unknown, any, any, string> | undefined;
}): UseInfiniteQuery => {
  const { openErrorToast } = useToast();

  const { data, ...rest } = reactQuery(queryKey, query, {
    getNextPageParam: (lastpage) => lastpage[queryName]?.nextToken || undefined,
    onError: () => {
      openErrorToast(errMessage);
    },
    refetchOnWindowFocus: false,
    ...options,
  });

  const mappedData = useMemo(() => {
    let items: any[] = [];
    data?.pages.map((page) => {
      items = [...items, ...page[queryName].items];
      return page;
    });
    return items;
  }, [data, queryName]);

  return {
    data: mappedData,
    rawData: data,
    lastPage: data?.pages?.slice(-1)[0][queryName],
    ...rest,
  };
};

export const useInfiniteQuery = ({
  queryName,
  queryKey,
  query,
  errMessage,
  options,
}: {
  queryName: string;
  queryKey: string;
  query: QueryFunction<any, string>;
  errMessage: string;
  options: UseInfiniteQueryOptions<any, unknown, any, any, string> | undefined;
}): UseInfiniteQuery => {
  const { openErrorToast } = useToast();

  const { data, ...rest } = reactQuery(queryKey, query, {
    getNextPageParam: (lastpage) => {
      const { currentPage, pages } = lastpage[queryName].pagination;
      if (pages === 0 || currentPage === pages) return undefined;
      return lastpage[queryName].pagination.currentPage + 1;
    },
    onError: () => {
      openErrorToast(errMessage);
    },
    refetchOnWindowFocus: false,
    ...options,
  });

  const mappedData = useMemo(() => {
    let items: any[] = [];
    data?.pages.map((d) => {
      items = [...items, ...d[queryName].items];
      return d;
    });
    return items;
  }, [data, queryName]);

  return {
    data: mappedData,
    rawData: data,
    lastPage: data?.pages?.slice(-1)[0][queryName],
    ...rest,
  };
};

export interface UseTransformedInfiniteQuery extends Omit<UseInfiniteQueryResult, 'data'> {
  data: InfiniteData<any[]>;
  lastPage?: {
    pagination: {
      pages: string;
      currentPage: number;
      hits: number;
    };
  };
}

// TODO: replace original usage with this when ready
export const useTransformedInfiniteQuery = ({
  queryName,
  queryKey,
  query,
  errMessage,
  options,
  transformer,
}: {
  queryName: string;
  queryKey: string;
  query: QueryFunction<any, string>;
  errMessage: string;
  transformer?: (record: any[]) => any[];
  options: UseInfiniteQueryOptions<any, unknown, any, any, string> | undefined;
}): UseTransformedInfiniteQuery => {
  const { openErrorToast } = useToast();

  const { data, ...rest } = reactQuery(queryKey, query, {
    getNextPageParam: (lastpage) => {
      const { currentPage, pages } = lastpage[queryName].pagination;
      if (pages === 0 || currentPage === pages) return undefined;
      return lastpage[queryName].pagination.currentPage + 1;
    },
    onError: () => {
      openErrorToast(errMessage);
    },
    refetchOnWindowFocus: false,
    select: transformer
      ? (infiniteData: InfiniteData<any>) => ({
          pages: infiniteData.pages.map((page) => transformer(page)),
          pageParams: infiniteData.pageParams,
        })
      : undefined,
    ...options,
  });

  // NOTE: we could add a getRawData if necessary

  return {
    data: data || { pages: [], pageParams: [] },
    lastPage: data?.pages?.slice(-1)[0][queryName],
    ...rest,
  };
};

import { gql } from 'graphql-request';

export const key = 'snapshot';
export const searchKey = `${key}.search`;

export const getOne = gql`
  query getDrawingsSnapshot($id: String!, $projectId: String!) {
    getDrawingsSnapshot(input: { id: $id, projectId: $projectId }) {
      created
      createdBy {
        id
      }
      id
      imageMetadata {
        cloudName
        publicId
      }
      modified
      project {
        id
        name
        number
      }
      sheet {
        id
        name
        number
        versionSet {
          id
          name
        }
      }
      snapshotSheetName
      snapshotSheetNumber
    }
  }
`;

export const getJobId = gql`
  query getDrawingsSnapshotJob($id: String!, $projectId: String!) {
    getDrawingsSnapshotJob(input: { id: $id, projectId: $projectId }) {
      created
      createdBy
      id
      modified
      projectId
      sheetId
      sheetName
      sheetNumber
      snapshotId
      status
      uploadLink {
        createdAt
        expiresAt
        url
        headers {
          name
          value
        }
      }
    }
  }
`;
export const listMany = gql`
  query listDrawingsSnapshots($cursor: String, $limit: Int, $projectId: String!) {
    listDrawingsSnapshots(input: { cursor: $cursor, limit: $limit, projectId: $projectId }) {
      cursor
      items {
        created
        id
        modified
        snapshotSheetName
        snapshotSheetNumber
        sheet {
          id
          name
          number
        }
        project {
          id
          name
          number
        }
        imageMetadata {
          cloudName
          publicId
        }
      }
    }
  }
`;

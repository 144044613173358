import React from 'react';
import { Text } from '@esub-engineering/react-component-library';
import { Box } from '@material-ui/core';
import useStyles from './styles';

export const UnauthorizedPage = React.memo(() => {
  const classes = useStyles();

  return (
    <Box className={classes.box}>
      <Text role="banner" className={classes.title} type="title" component="legend">
        Unauthorized Access
      </Text>
      <Text className={classes.content}>You do not have permissions to view this page.</Text>
    </Box>
  );
});

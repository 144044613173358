import { IUserInputApi } from '../../types';

export const initialState: Partial<IUserInputApi> = {
  crewStatus: 'AVAILABLE',
  languagePreference: 'en',
  dateDisplayPreference: 'mdy',
  type: 'STANDARD',
  primaryLaborClass: 'Labor',
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export default (state = initialState, { type, payload }: any) => {
  switch (type) {
    case 'SET_USER_DATA':
      return {
        ...state,
        ...payload,
      };
    case 'CLEAR_USER_FORM':
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

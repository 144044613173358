import React from 'react';
import { Container, Paper, Box } from '@material-ui/core';
import { ActivateAccountWrapper } from '@esub-engineering/common-containers';
import { useDispatch } from 'react-redux';
import { Page } from '@esub-engineering/react-component-library';
import { openToast } from '../../../common/redux/toast';
import { EmptyPageWrapper } from '../../../common/containers';

export const ActivateAccountPage = React.memo(() => {
  const dispatch = useDispatch();

  return (
    <EmptyPageWrapper>
      <Page>
        <Container maxWidth="sm">
          <br />
          <Paper>
            <Box m={2} p={2}>
              <ActivateAccountWrapper
                onSuccess={() =>
                  dispatch(
                    openToast({
                      type: 'success',
                      message: 'Activation Code Sent',
                    })
                  )
                }
                onError={() =>
                  dispatch(
                    openToast({
                      type: 'error',
                      message: 'Unable to Request Activation Code',
                    })
                  )
                }
              />
            </Box>
          </Paper>
        </Container>
      </Page>
    </EmptyPageWrapper>
  );
});

import { themes } from '@esub-engineering/react-component-library';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const { materialColors } = themes;
const useStyles = makeStyles(() =>
  createStyles({
    downloadText: {
      color: materialColors.grey[600],
    },
    fileSelection: {
      paddingLeft: '10px',
    },
  })
);

export default useStyles;

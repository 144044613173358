import { getStageFromLocation } from '@esub-engineering/react-common-lib';
import React, { useEffect, useMemo } from 'react';

export const ZendeskWidget = React.memo(() => {
  const { NODE_ENV } = process.env;
  const stage = useMemo(() => getStageFromLocation(window.location.hostname), []);

  useEffect(() => {
    const zendeskId = 'ze-snippet';
    const environments: string[] = ['prod', 'demo', 'stage', 'dr'];
    if (NODE_ENV === 'production' && environments.includes(stage)) {
      const script = document.createElement('script');
      script.id = zendeskId;
      script.src =
        'https://static.zdassets.com/ekr/snippet.js?key=f7b87d92-d7f3-4d9d-9424-4e3894ff7033';

      // append script to body of document
      document.body.appendChild(script);
    }
    return () => {
      const zendeskElement = document.getElementById(zendeskId);
      if (zendeskElement) {
        document.body.removeChild(zendeskElement);
      }
    };
  }, [NODE_ENV, stage]);

  return null;
});

/**
 * Swap array items
 * @param {array} arr
 * @param {number} i1 index item to swap into i2
 * @param {number} i2 index item to swap into i1
 * @returns {array} array with items swapped
 */
export const swapArrayElements = <T>(arr: Array<T>, i1: number, i2: number): Array<T> => {
  const result = arr.splice(i1, 1, arr[i2]);
  arr.splice(i2, 1, result[0]);

  return arr;
};

import { DrawingsSnapshotJobStatus, SheetsOcrJobStatus } from "@esub-engineering/platform-types";
import { VersionSetPdfJobStatus } from "../../../drawings/hooks/useGetVersionSetPdfJob/types";

export type AsyncCenterState = {
  getVersionSetPdfJobId: string;
  getVersionSetPdfJobStatus?: VersionSetPdfJobStatus;
  getSheetsOcrJobId: string;
  getSheetsOcrJobStatus?: SheetsOcrJobStatus;
  getSnapshotJobId: string;
  getSnapshotJobStatus?: DrawingsSnapshotJobStatus;
  snapshotId?: string;
  projectId: string;
}

export enum AsyncCenterTypes {
  setPdfJob = 'setPdfJob',
  setOcrJob = 'setOcrJob',
  setSnapshotJob = 'setSnapshotJob',
  reset = 'reset'
}

import { combineReducers } from 'redux';
import toast from '../common/redux/toast/reducers';
import progressIndicator from '../common/redux/progressIndicator/reducers';
import currentUser from '../auth/redux/currentUser/reducers';
import { reducer as organization } from '../organization/redux';
import page from '../common/redux/page/reducers';
import userForm from '../user/redux/userForm/reducers';
import entityAssignments from '../user/redux/entityAssignments/reducers';
import tableFilters from '../common/redux/tableFilters/reducers';
import costCodes from '../costCode/redux/costCodes/reducers';
import dailyReports from '../dailyReport/redux/reducers';
import stickyEntityAssignments from '../user/redux/stickyEntityAssignments/reducers';

export default combineReducers({
  toast,
  currentUser,
  organization,
  progressIndicator,
  page, // TODO: this is deprecated
  userForm,
  costCodes,
  dailyReports,
  entityAssignments,
  stickyEntityAssignments,
  tableFilters,
});

import React from 'react';
import { Link } from 'react-router-dom';
import useStyles from './styles';
import { DataGridDocumentLinkProps } from '../../types';

export const DataGridDocumentLink = React.memo<DataGridDocumentLinkProps>(({ path, children }) => {
  const classes = useStyles();

  return (
    <Link to={path} className={`projectDocumentLink ${classes.root}`} data-testid='dataGridLink'>
      {children}
    </Link>
  );
});

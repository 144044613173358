import { reportError } from '@esub-engineering/react-common-lib';
import React from 'react';

const storageKey = 'esub.lz.refreshed';

const recoverAppFromError = () => {
  const hasRefreshed = JSON.parse(sessionStorage.getItem(storageKey) || 'false') as boolean;

  if (!hasRefreshed) {
    sessionStorage.setItem(storageKey, 'true');
    window.location.reload();
    return;
  }

  sessionStorage.setItem(storageKey, 'false');
};

export class RouteErrorBoundary extends React.Component<
  { children: React.ReactNode },
  { hasError: boolean }
> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  // static getDerivedStateFromError(error: any) {
  //   // Update state so the next render will show fallback UI.
  //   return { hasError: true };
  // }

  componentDidCatch(error: unknown, info: unknown) {
    // eslint-disable-next-line no-console
    console.log('recovering app ', { error, info });
    recoverAppFromError();
    reportError({
      error,
      context: { info, method: 'RouteErrorBoundary' },
    });
    this.setState({ hasError: true });
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return null;
    }

    return children;
  }
}

import { useCallback } from 'react';
import i18nNext, { i18n as i18nInterface } from 'i18next';
import { initReactI18next, useTranslation, Trans } from 'react-i18next';
import commonTranslationsEN from '../../../i18n/locales/en/common.json';
import commonTranslationsES from '../../../i18n/locales/es/common.json';

type UseI18nApi = {
  i18n: i18nInterface;
  translate: (key: string) => unknown;
  Trans: any;
  initI18nConfig: (lng: I18nLanguages) => void;
};

export type I18nLanguages = 'en' | 'es';

export const useI18n = (): UseI18nApi => {
  const { t: translate, i18n } = useTranslation();

  const initI18nConfig = useCallback((lng: I18nLanguages = 'en') => {
    i18nNext.use(initReactI18next).init({
      fallbackLng: 'en',
      lng,
      resources: {
        en: {
          common: commonTranslationsEN,
        },
        es: {
          common: commonTranslationsES,
        },
      },
      ns: ['common'],
      defaultNS: 'common',
    });

    i18nNext.languages = ['en', 'es'];
  }, []);

  // api
  return {
    // the i18n instance, useful when needing access to the instance itself
    // example: i18n.changeLanguage()
    i18n,
    // translate text. Ex: translate('existing_key_in_translations_file')
    translate,
    // initialize and configure i18next
    initI18nConfig,
    // interpolate or translate complex React elements: https://react.i18next.com/latest/trans-component
    Trans,
  };
};

import { IAddressApi } from '../../api';

export const formatAddressLine = ({
  street1,
  street2,
  city,
  state,
  zipCode,
}: Partial<IAddressApi>): string => {
  const commaDelimiter = (street1 || street2) && (city || state || zipCode);
  return `${street1 ?? ''} ${street2 ?? ''}${commaDelimiter ? ',' : ''}${city ?? ''} ${
    state ?? ''
  } ${zipCode ?? ''}`;
};

import { themes } from '@esub-engineering/react-component-library';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const { materialColors } = themes;
const useStyles = makeStyles(() =>
  createStyles({
    button: {
      color: materialColors.blue[700],
    },
  })
);

export default useStyles;

/**
 * Returns an object with all its values wrapped in double quotes, so commas dont break the CSV formatting
 * @param {Object} obj - Object to wrap its values
 */
export const csvObjectStringify = (obj: { [key: string]: any }) => Object.keys(obj).reduce(
  (acc, key) => ({
    ...acc,
    ...(obj[key] === null || obj[key] === undefined ? { [key]: '""' } : { [key]: `"${obj[key]}"` }),
  }),
  {}
)

/**
 * Returns an array with all its items wrapped in double quotes, so commas dont break the CSV formatting
 * @param {Array<any>} arr - Object to wrap its values
 */
export const csvArrayStringify = (arr: Array<any>) => (
  arr.map((item) => (
    item === null || item === undefined ? '""' : `"${item}"`
  ))
)

export const numberToCurrency = (number: number = 0) => {
  // handle NaN values 🤦‍♂️
  // could be related to data issues in lower envs
  if (String(number) === 'NaN') {
    return '';
  }

  const maxDigits = number.toString().indexOf('.') > 0 ? number.toString().length : undefined;
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumSignificantDigits: maxDigits,
  });
  const formatted = formatter.format(number);
  const trailingZeroHasBeenTrimmed = formatted.split('.')[1]?.length === 1;

  return trailingZeroHasBeenTrimmed ? `${formatted}0` : formatted;
};

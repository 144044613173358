import React from 'react';
import { DateText, DateDisplayPref } from '@esub-engineering/react-component-library';
import { useCurrentUser } from '../../../auth/hooks';

export const DateCell = ({ value }: any) => {
  const { currentUser } = useCurrentUser();

  return (
    <DateText displayPref={(currentUser?.data?.dateDisplayPreference as DateDisplayPref) || 'dmy'}>
      {value}
    </DateText>
  );
};

import React from 'react';
import { Fieldset } from '@esub-engineering/react-component-library';
import { Grid } from '@material-ui/core';
import { ControlledTextField } from '@esub-engineering/common-containers';
import { EmailFormProps } from './types';
import { ContactSelector } from '../ContactSelector';
import { FileSelector } from '../FileSelector';

export const EmailForm = React.memo(
  ({ control, list, setList, handleCheckAll, noList, reportName = '' }: EmailFormProps) => (
    <Grid container spacing={3} style={{ paddingBottom: list.length > 2 ? '200px' : '0px' }}>
      <Grid item xs={12}>
        <Fieldset title="" divider={false}>
          <ContactSelector name="to" control={control} label="TO" id="to" isEmail />
          <ContactSelector name="cc" control={control} label="CC" id="cc" isEmail />
          <ContactSelector name="bcc" control={control} label="BCC" id="bcc" isEmail />
          <ControlledTextField
            name="subject"
            control={control}
            label="SUBJECT"
            id="subject"
            fullWidth
          />
        </Fieldset>
        <Fieldset title="ADDITIONAL COMMENTS" divider={false} multilineBox>
          <ControlledTextField
            control={control}
            variant="enclosed"
            name="comments"
            id="comments"
            border="outline"
            label="Enter text here"
            multiline
            placeholder="Enter text here"
            minRows="5"
            fullWidth
          />
        </Fieldset>
      </Grid>
      {list.length > 0 && !noList ? (
        <FileSelector
          reportName={reportName}
          list={list}
          setList={setList}
          handleCheckAll={handleCheckAll}
        />
      ) : undefined}
    </Grid>
  )
);

import { SheetsOcrJobStatus } from '@esub-engineering/platform-types';
import { useGraphQuery, useToast } from '../../../common/hooks';
import { getSheetsOcrJob, key } from '../../API/queries';
import { Return, TData, UseGetSheetsOcrJobProps } from './types';

export const useGetSheetsOcrJob = ({ id, projectId, ...rest }: UseGetSheetsOcrJobProps) => {
  const { openErrorToast } = useToast();
  const responseTForm = (d: Return): TData => ({ ...d.getSheetsOcrJob });

  return useGraphQuery<Return, Error, TData, [string, string]>({
    key: [key, id],
    gql: getSheetsOcrJob,
    variables: { id, projectId },
    responseTForm,
    onError: () => openErrorToast('Unable to retrieve PDF job info.'),
    validateClientResponseData: (d) => {
      if (d.getSheetsOcrJob.status === SheetsOcrJobStatus.Succeeded) return true;
      if (d.getSheetsOcrJob.status === SheetsOcrJobStatus.Failed) return true;
      return false;
    },
    retry: true,
    retryDelay: 1000,
    ...rest,
  });
};

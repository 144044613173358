import { companyQueries } from '@esub-engineering/react-common-lib';
import { useGraphQuery } from '../useGraphQuery';
import { IUseGraphQueryProps } from '../useGraphQuery/types';

interface IGetCompanyQuery extends Partial<IUseGraphQueryProps> {
  variables: {
    id?: string;
  };
}


/* 
  TODO: This query should be migrated to use useCachedGraphQuery and also apply types to the response right now is any
  When this TODO is resolved you should remove useCachedCompany from services\customer-web-app\src\company\hooks\useCompany\index.ts and replace 
  all the uses of that hook with this one
*/

 export const useGetCompanyQuery = ({ variables, ...rest }: IGetCompanyQuery) =>
  useGraphQuery({
    enabled: Boolean(variables.id),
    key: [companyQueries.key, `${variables.id}`],
    gql: companyQueries.getCompany,
    variables,
    ...rest,
  });

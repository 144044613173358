import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles(() =>
  createStyles({
    root: () => ({
      display: 'flex',
      justifyContent: 'space-evenly',
      alignItems: 'center',
    }),
  })
);

import { useMemo, useCallback } from 'react';
import { TDailyReportSettingsApi } from '@esub-engineering/react-common-lib';
import { isInEditWindow } from '../../utils/isInEditWidow';
import { defaults } from '../../config';
import { IGetCompanyApiResponse } from '../../../company/types';

/**
 * Hook to provide edit window calculations given project and company data
 * More stateful usage for isInEditWindow utility
 */
export const useEditWindow = ({
  dailyReportSettings,
  company,
}: {
  dailyReportSettings?: TDailyReportSettingsApi;
  company?: IGetCompanyApiResponse;
}) => {
  // get project edit window
  const projectEditWindow = useCallback(
    (date) => ({
      itemDate: date,
      windowSize: dailyReportSettings?.dailyReportEditWindow || defaults.editWindow,
      noLimit: dailyReportSettings?.dailyReportEditWindowUnlimited || false,
    }),
    [
      dailyReportSettings?.dailyReportEditWindow,
      dailyReportSettings?.dailyReportEditWindowUnlimited,
    ]
  );

  // get company edit window
  const companyEditWindow = useCallback(
    (date) => ({
      itemDate: date,
      windowSize:
        company?.getCompany?.dailyReportSettings?.dailyReportEditWindow || defaults.editWindow,
      noLimit: company?.getCompany?.dailyReportSettings?.dailyReportEditWindowUnlimited || false,
    }),
    [company]
  );

  // decide to use project edit window, alternative is company
  const shouldUseProjectEditWindow = useMemo(
    () =>
      Boolean(dailyReportSettings?.dailyReportEditWindowUnlimited) ||
      Boolean(dailyReportSettings?.dailyReportEditWindow),
    [
      dailyReportSettings?.dailyReportEditWindowUnlimited,
      dailyReportSettings?.dailyReportEditWindow,
    ]
  );

  // determine if date is within edit window
  const getWithinEditWindow = useCallback(
    (date: Date | string): boolean =>
      shouldUseProjectEditWindow
        ? isInEditWindow(projectEditWindow(date))
        : isInEditWindow(companyEditWindow(date)),
    [shouldUseProjectEditWindow, projectEditWindow, companyEditWindow]
  );

  return { getWithinEditWindow };
};

import React, { useContext } from 'react';
import { GraphQLClient } from 'graphql-request';
import { getEnvironment } from '../../utils';

export const Context = React.createContext<GraphQLClient | null>(null);

// Cube Cloud gql endpoint
const analyticsGqlEndpoints = Object.freeze({
  dev: 'https://cube.dev.esubcloud.com/cubejs-api/graphql',
  qa: 'https://cube.qa.esubcloud.com/cubejs-api/graphql',
  stage: 'https://cube.stage.esubcloud.com/cubejs-api/graphql',
  migration: 'https://cube.migration.esubcloud.com/cubejs-api/graphql',
  demo: 'https://cube.demo.esubcloud.com/cubejs-api/graphql',
  production: 'https://cube.esubcloud.com/cubejs-api/graphql',
});

const getAnalyticsGqlEndpoint = () => {
  const environment = getEnvironment({ skipNodeEnv: true });

  return analyticsGqlEndpoints[environment as keyof typeof analyticsGqlEndpoints];
};

export const AnalyticsGraphQLClientProvider = React.memo(
  ({ children }: { children: React.ReactChild | React.ReactChild[] }) => {
    const endpoint = getAnalyticsGqlEndpoint();
    const client = React.useMemo(() => new GraphQLClient(endpoint), [endpoint]);

    return <Context.Provider value={client}>{children}</Context.Provider>;
  }
);

export const useAnalyticsGraphQLClient = () => useContext(Context);

import React, { useState } from 'react';
import { useGraphQLClient } from '@esub-engineering/common-containers';
import { MutateOptions, useMutation, useQueryClient } from 'react-query';
import { IUseGraphMutationProps } from './types';

export const useGraphMutation = <
  TData = any,
  TError = Error,
  TVariables = any,
  TContext = unknown,
  Response = any
>({
  key: mutationKey,
  gql,
  // TODO: deprecate delayKey and delay
  delayKey,
  delay = 1000,
  // optional request headers
  requestHeaders = {},
  defaultInputProps,
  responseTForm,
  ...rest
}: IUseGraphMutationProps<TData, TError, TVariables, TContext, Response>) => {
  const [isDelayed, setIsDelayed] = useState(false);
  const client = useGraphQLClient();
  const queryClient = useQueryClient();

  const request = React.useCallback(
    async (input) => {
      const data = await client!.request(
        gql,
        {
          ...input,
        },
        requestHeaders
      );
      return responseTForm ? responseTForm(data) : data;
    },
    [client, gql, requestHeaders, responseTForm]
  );

  const { isLoading: loading, mutateAsync: mutateWithDelay, ...other } = useMutation<
    TData,
    TError,
    TVariables,
    TContext
  >(
    (input: any) =>
      request({
        ...defaultInputProps,
        ...input,
      }),
    {
      mutationKey,
      ...rest,
      onSuccess: async (d, v, c) => {
        if (delayKey) {
          setIsDelayed(true);
          await setTimeout(() => {
            queryClient.invalidateQueries(delayKey);
            setIsDelayed(false);
            if (rest?.onSuccess) rest.onSuccess(d, v, c);
          }, delay);
        } else if (rest?.onSuccess) rest.onSuccess(d, v, c);
      },
    }
  );

  const mutateAsync = (
    variables: TVariables,
    options?: MutateOptions<TData, TError, TVariables, TContext> | undefined
  ) =>
    mutateWithDelay(variables, {
      ...options,
      onSuccess: async (d, v, c) => {
        if (delayKey) {
          setIsDelayed(true);
          await setTimeout(() => {
            queryClient.invalidateQueries(delayKey);
            setIsDelayed(false);
            if (options?.onSuccess) options.onSuccess(d, v, c);
          }, delay);
        } else if (options?.onSuccess) options.onSuccess(d, v, c);
      },
    });

  const isLoading = loading || isDelayed;

  return { isLoading, mutateAsync, ...other };
};

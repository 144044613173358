import React, { useEffect, useMemo } from 'react';
import { useI18n, I18nLanguages } from '../../hooks';
import { useCurrentUser } from '../../../auth/hooks';

type Props = {
  children: React.ReactNode | React.ReactChild | React.ReactChildren;
};

export const I18n = React.memo(({ children }: Props) => {
  const { currentUser } = useCurrentUser();
  const { initI18nConfig } = useI18n();

  const userLanguagePreference = useMemo(() => currentUser?.data?.languagePreference, [
    currentUser?.data?.languagePreference,
  ]);

  useEffect(() => {
    if (userLanguagePreference) {
      initI18nConfig(userLanguagePreference as I18nLanguages);
    }
  }, [userLanguagePreference, initI18nConfig]);

  return <>{children}</>;
});

// DEFAULT SETTINGS FOR CHARTS
export const chartSettings = Object.freeze({
  height: 250,
  width: 500,
});

export const legendColors = [
  '#3A5E82',
  '#CB7b33',
  '#609CD5',
  '#5752D1',
  '#F4C144',
  '#EC7A08',
  '#47573F',
  '#E47A7A',
  '#6872CC',
  '#512A5B',
  '#60894D',
  '#A6A1C6',
  '#511A1A',
  '#585A6D',
  '#DCB8E5',
];

import React from 'react';
import { Button } from '@esub-engineering/react-component-library';
import useStyles from './styles';

type TProps = {
  acceptedFileTypes: string // ex: '.csv'
  onChange: (file: File) => void
}

export const FileImport = React.memo(({ acceptedFileTypes, onChange }: TProps) => {
  const classes = useStyles();

  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    if (evt.target.files && typeof onChange === 'function') {
      const files = Array.from(evt.target.files)
      const [file] = files
      onChange(file)
    }
  }

  return (
    <Button id="importFileBtn" component="label" variant="contained">
        Choose file
        <input
          id="ImportFileInput"
          name="importFile"
          type="file"
          accept={acceptedFileTypes}
          onChange={handleChange}
          className={classes.input}
          hidden
        />
      </Button>
  );
});

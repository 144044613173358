import React, { useCallback, useState } from 'react';
import base64 from 'base-64';
import { LoadingIndicator } from '@esub-engineering/react-component-library';
import { AuthStorage, getLocalStorage, userQueries, TUserApi, TJWTPayload } from '@esub-engineering/react-common-lib';
import { useDispatch } from 'react-redux';
import { useGraphQLClient } from '@esub-engineering/common-containers';
import { useQuery } from 'react-query';
import { setUser } from '../../redux/currentUser/actions';
import { initWebAnalytics } from '../../../common/utils';
import { IInitCurrentUserProps } from './types';

// TODO: this can probably move to user entity in the future and should be written as a hook
export const InitCurrentUser = React.memo(({ children }: IInitCurrentUserProps) => {
  const [decodedToken, setDecodedToken] = useState<TJWTPayload | null>(null);

  const dispatch = useDispatch();

  const decodeIdToken = useCallback(() => {
    const idToken = getLocalStorage(AuthStorage.ID_TOKEN);
    const { 1: payload } = idToken.split('.');

    return payload ? JSON.parse(base64.decode(payload)) : null;
  }, []);

  const setUserStore = useCallback(
    (data: TUserApi) => {
      const decoded: TJWTPayload | null = decodeIdToken();
      const permissions = getLocalStorage(AuthStorage.PERMISSIONS);

      const { NODE_ENV } = process.env;
      setDecodedToken(decoded);

      // TODO: move this, this should be a component that calls useCurrentUser
      if (NODE_ENV === 'production' && decoded && data) {
        // in your authentication promise handler or callback
        // @ts-ignore: Unreachable code error
        pendo.initialize({
          visitor: {
            id: decoded['custom:userId'], // Required if user is logged in
            email: decoded.email, // Recommended if using Pendo Feedback, or NPS Email
            // full_name: // Recommended if using Pendo Feedback
            // role: // Optional
            // You can add any additional visitor level key-values here,
            // as long as it's not one of the above reserved names.
          },
          account: {
            // initialize pendo with companyId - CP-4115
            id: data?.defaultCompany, // Required if using Pendo Feedback
            // name: // Optional
            // is_paying: // Recommended if using Pendo Feedback
            // monthly_value:// Recommended if using Pendo Feedback
            // planLevel: // Optional
            // planPrice: // Optional
            // creationDate: // Optional
            // You can add any additional account level key-values here,
            // as long as it's not one of the above reserved names.
          },
        });
      }

      dispatch(
        setUser({
          token: decoded || undefined,
          permissions: permissions ? JSON.parse(permissions) : undefined,
        })
      );
    },
    [decodeIdToken, dispatch]
  );

  const client = useGraphQLClient();

  const { key, getUser } = userQueries;
  const userId = decodedToken ? decodedToken['custom:userId'] : undefined;

  const userQuery = useCallback(async () => {
    if (decodedToken) {
      const result = await client!.request(getUser, { id: decodedToken?.['custom:userId'] });
      const data = result?.getUser;
      initWebAnalytics(data);
      dispatch(setUser({ data }));
      return data;
    }
    return null;
  }, [client, decodedToken, dispatch, getUser]);

  const { isLoading } = useQuery<TUserApi, Error>(userId ? [key, userId] : key, userQuery, {
    refetchOnWindowFocus: false,
    onSuccess: (data) => setUserStore(data),
  });

  return (
    <LoadingIndicator loading={isLoading} fullscreen>
      {children}
    </LoadingIndicator>
  );
});

import { gql } from 'graphql-request';

export const getPdfJob = gql`
  query getPdfJob($id: String!) {
    getPdfJob(input: { id: $id }) {
      created
      id
      modified
      result {
        createdAt
        expiresAt
        headers {
          name
          value
        }
        link
      }
      status
      type
    }
  }
`;

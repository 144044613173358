import { TPageState, TPageActionPayload } from './types';

const initialState: TPageState = {
  isEditing: false,
  isDirty: false,
  isCreating: false,
  isFormEdited: false,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export default (state = initialState, { type, payload }: TPageActionPayload) => {
  switch (type) {
    case 'SET_PAGE':
      return {
        ...state,
        ...payload,
      };
    case 'RESET_PAGE':
      return initialState;
    default:
      return state;
  }
};

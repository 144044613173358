import base64 from 'base-64';
import { useHistory } from 'react-router';
import { useCallback } from 'react';
import { IUseAuthProps, TParsedJWT, IUseAuthResult } from './types';

/**
 * Enum of storage keys
 */
export const enum AuthStorage {
  REMEMBERED_USERNAME = 'rememberedUsername',
  ACCESS_TOKEN = 'accessToken',
  ID_TOKEN = 'idToken',
  REFRESH_TOKEN = 'refreshToken',
  PERMISSIONS = 'permissions',
}

/**
 * Parse JWT string into payload, header and signature values
 * @param token
 * @returns
 */
export const parseToken = (token?: string | null) => {
  const parseResult: TParsedJWT = {
    header: null,
    payload: null,
    signature: null,
  };

  // do not try and parse if token is null or undefined
  if (!token) return parseResult;

  // try and parse
  try {
    // split JWT accordingly using HEADER.PAYLOAD.SIGNATURE
    const { 0: header, 1: payload, 2: signature } = token.split('.');

    parseResult.payload = payload ? JSON.parse(base64.decode(payload)) : null;
    parseResult.header = header ? JSON.parse(base64.decode(header)) : null;
    // dont decode signature
    parseResult.signature = signature || null;
  } catch (ex) {
    // eslint-disable-next-line no-console
    console.error('Unable to parse JWT', ex);
  }

  return parseResult;
};

/**
 * Check to see if JWT has any missing parts
 * @param token
 * @returns
 */
export const isTokenEmpty = (token: TParsedJWT) =>
  !token?.header || !token?.payload || !token?.signature;

/**
 * Get idToken from localStorage
 * @returns
 */
export const getIdToken = () => localStorage.getItem(AuthStorage.ID_TOKEN);

/**
 * Set idToken to localStorage
 * @param token
 */
export const setIdToken = (token: string) => {
  localStorage.setItem(AuthStorage.ID_TOKEN, token);
};

/**
 * Get accessToken from localStorage
 * @returns
 */
export const getAccessToken = () => localStorage.getItem(AuthStorage.ACCESS_TOKEN);

/**
 * Set access token to localStorage
 * @param token
 */
export const setAccessToken = (token: string) => {
  localStorage.setItem(AuthStorage.ACCESS_TOKEN, token);
};

/**
 * Get refresh token from localStorage
 * TODO: move this from localStorage to platform BE
 */
export const getRefreshToken = () => localStorage.getItem(AuthStorage.REFRESH_TOKEN);

/**
 * Clear refresh token
 * @returns
 */
export const clearRefreshToken = () => localStorage.removeItem(AuthStorage.REFRESH_TOKEN);

/**
 * Exchange refresh token for new, longer lasting access token
 * @see https://docs.aws.amazon.com/cognito/latest/developerguide/token-endpoint.html
 */
export const getFreshTokens = async () => {
  // need data from access token
  const { payload } = parseToken(getAccessToken());

  // current refresh token, will be NULL after refresh token exchange by design
  const refreshToken = getRefreshToken();

  // response object with defaults
  const refreshResponse = {
    id_token: null,
    access_token: null,
  };

  // need identifiers to proceed
  const clientId = payload?.client_id;
  const iss = payload?.iss;

  if (!clientId || !iss || !refreshToken) {
    return refreshResponse;
  }

  // create headers following cognito api
  const headers = new Headers({
    'Content-Type': 'application/x-www-form-urlencoded',
    Authorization: `Basic ${base64.encode(clientId)}`,
  });

  // create request body following cognito api
  const body = {
    grant_type: 'refresh_token',
    client_id: clientId,
    refresh_token: refreshToken,
  };

  // create traditional fetch request
  const request = new Request(`${iss}/oauth2/token`, {
    method: 'POST',
    headers,
    body: JSON.stringify(body),
  });

  try {
    // fetch
    const response = await fetch(request);

    // parse
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { id_token, access_token } = await response.json();

    // something happened and we don't have the tokens we expect
    if (!id_token || !access_token) {
      throw new Error('Missing tokens from refresh response');
    }

    // set the response
    refreshResponse.id_token = id_token;
    refreshResponse.access_token = access_token;
  } catch (ex) {
    // eslint-disable-next-line no-console
    console.error('Error refreshing user token', ex);
  }

  // always return a response
  return refreshResponse;
};

/**
 * Clear local storage objects associated with auth
 */
export const clearAuthStorage = () => {
  localStorage.removeItem(AuthStorage.ACCESS_TOKEN);
  localStorage.removeItem(AuthStorage.ID_TOKEN);
  localStorage.removeItem(AuthStorage.REFRESH_TOKEN);
  localStorage.removeItem(AuthStorage.PERMISSIONS);
};

/**
 * Require token, and maintain its lifecycle
 * @param config
 */
export const syncAndVerifyTokens = async ({
  refreshBufferInSeconds,
  onExpire,
}: {
  refreshBufferInSeconds: number;
  onExpire: () => void;
}) => {
  const idToken = parseToken(getIdToken());
  const accessToken = parseToken(getAccessToken());

  // check for empty tokens and force expiry
  if (isTokenEmpty(idToken) || isTokenEmpty(accessToken)) {
    clearAuthStorage();
    onExpire();
    return;
  }

  const refreshToken = getRefreshToken();

  // convert to seconds2
  const now = Math.round(Date.now() / 1000);

  // allow for a window before access token expiration for refresh
  const refresh = Math.round(Date.now() / 1000 + refreshBufferInSeconds);

  // determine if the token expired
  const expired = Boolean(idToken.payload?.exp) && idToken.payload.exp < now;

  // determine if we should attempt to refresh
  const shouldRefresh =
    !expired &&
    Boolean(refreshToken) &&
    Boolean(idToken.payload?.exp) &&
    idToken.payload.exp < refresh;

  if (shouldRefresh) {
    // token will expire soon, attempt to get a new one

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { id_token, access_token } = await getFreshTokens();

    if (!id_token || !access_token) {
      // because refresh result can fail
      clearAuthStorage();
      onExpire();
    } else {
      setIdToken(`${id_token}`);
      setAccessToken(`${access_token}`);
    }
  } else if (expired) {
    // token is expired and outside the range for refresh

    clearAuthStorage();
    onExpire();
  }
};

/**
 * Get permission groups / permissions
 * @returns
 */
export const getPermissions = () => localStorage.getItem(AuthStorage.PERMISSIONS);

/**
 * Hook for common auth functionality
 * @param props
 * @returns
 */
export const useAuth = ({
  refreshBufferInSeconds = 5 * 60,
  logoutUri = '/login',
}: IUseAuthProps = {}): IUseAuthResult => {
  const history = useHistory();

  return {
    parseToken: useCallback(parseToken, []),
    getIdToken: useCallback(getIdToken, []),
    getAccessToken: useCallback(getAccessToken, []),
    getPermissions: useCallback(getPermissions, []),
    syncAndVerifyTokens: useCallback(() => {
      const onExpire = () => history.push(logoutUri);
      return syncAndVerifyTokens({ onExpire, refreshBufferInSeconds });
    }, [history, logoutUri, refreshBufferInSeconds]),
  };
};

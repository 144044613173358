import React, {
  ReactNode,
  createContext,
  useReducer,
  useContext,
  Reducer,
  ReducerState,
  Dispatch,
} from 'react';

export type Action<Types> = {
  type: Types;
  payload: any;
};

export type StoreProviderProps<State> = {
  children: ReactNode;
  customDefaults?: State;
};

export default function makeStore<State, Types>(
  userReducer: Reducer<State, Action<Types>>,
  userInitialState: ReducerState<Reducer<State, Action<Types>>>,
  key?: string
) {
  const storeContext = createContext(userInitialState);
  const dispatchContext = createContext<Dispatch<Action<Types>>>((a: Action<Types>) => a);
  let initialState = userInitialState;

  try {
    if (key) {
      const storedState = localStorage.getItem(key);
      if (storedState) initialState = JSON.parse(storedState);
    }
  } catch (err: any) {
    throw new Error('Super Provider Persistance Error', err);
  }

  const reducer: Reducer<State, Action<Types>> = (previousState, action) => {
    const newState = userReducer(previousState, action);
    if (key) localStorage.setItem(key, JSON.stringify(newState));
    return newState;
  };

  const StoreProvider = ({ children, customDefaults }: StoreProviderProps<State>) => {
    const [store, dispatch] = useReducer(reducer, customDefaults || initialState);

    return (
      <dispatchContext.Provider value={dispatch}>
        <storeContext.Provider value={store}>{children}</storeContext.Provider>
      </dispatchContext.Provider>
    );
  };

  function useStore() {
    return useContext(storeContext);
  }

  function useDispatch() {
    return useContext(dispatchContext);
  }

  return { StoreProvider, useDispatch, useStore };
}

// TODO: docblock this and it will show up as an example
// TODO: LEAVE THIS HERE FOR REFERENCE
// type Todo = string;

// type TodosState = {
//   todos: Todo[]
// }

// enum TodosTypes {
//   addTodo = 'addTodo',
//   deleteTodo = 'deleteTodo',
// }

// const todosReducer:Reducer<TodosState, Action<TodosTypes>> = (state, action) => {
//   switch (action.type) {
//     case TodosTypes.addTodo:
//         return {
//           ...state,
//           todos: [
//             ...state.todos,
//             action.payload
//           ]
//         }
//     case TodosTypes.deleteTodo:
//         return {
//           ...state,
//           todos: state.todos.filter(t => t === action.payload)
//         }
//     default:
//       return state
//   }
// }

// const initialState:TodosState = {
//   todos: []
// }

// const {
//   StoreProvider: TodosProvider,
//   useDispatch: useTodosDispatch,
//   useStore: useTodosStore,
// } = makeStore<TodosState, TodosTypes>(todosReducer, initialState)

// const useTodosActions = () => {
//   const dispatch = useTodosDispatch()

//   return {
//     addTodo: (payload:string) => dispatch({
//       type: TodosTypes.addTodo,
//       payload
//     }),
//     deleteTodo: (payload:string) => dispatch({
//       type: TodosTypes.deleteTodo,
//       payload
//     })
//   }
// }

// export { TodosProvider, useTodosStore, useTodosActions }

import { useToast } from '../useToast';
import { useGraphQuery } from '../useGraphQuery';
import { IUseGraphQueryProps } from '../useGraphQuery/types';
import { getPdfJob } from '../../queries';
import { PdfJob } from '../../types/pdf';

type GetPdfJobInput = {
  id?: string;
};

// TODO: should PdfJob type live here as well
type GetPdfJobResult = {
  getPdfJob: PdfJob;
};

interface UseGetPdfJobInputArgs
  extends Omit<IUseGraphQueryProps<GetPdfJobResult, Error>, 'key' | 'gql'>,
    GetPdfJobInput {}

/**
 * Get result of a PDF job with built in polling AND dependent query check
 */
export const useGetPdfJob = ({ id, ...rest }: UseGetPdfJobInputArgs) => {
  const { openErrorToast } = useToast();

  return useGraphQuery<GetPdfJobResult, Error, GetPdfJobResult, GetPdfJobInput>({
    ...rest,
    // treat this as a dependent query based on pdf job id
    enabled: !!id,
    key: ['pdfJob', id || 'unknown'],
    gql: getPdfJob,
    variables: { id },
    onError: () => openErrorToast('Unable to retrieve pdf data.'),
    // fail if we do not get result.link OR if the status is failed -- triggering auto retry
    validateClientResponseData: (data) =>
      Boolean(data?.getPdfJob?.result?.link) || data?.getPdfJob?.status === 'FAILED',
    // retry logic needs to be enough attempts to allow for polling
    retry: 30,
    retryDelay: 1000,
  });
};

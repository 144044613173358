import { useState, useCallback } from 'react';

export const useSearchBar = (initialTerm: string = '') => {
  const [term, setTerm] = useState(initialTerm);
  const onSearchChange = useCallback((e) => setTerm(e.target.value || ''), []);

  const onSearchClear = useCallback(() => setTerm(''), []);

  return { term, onSearchChange, onSearchClear };
};

import React, {  useMemo } from 'react';
import { getInputDateFormat, getGenericDateFormat } from '@esub-engineering/react-common-lib';
import { useCurrentUser } from '../../../auth/hooks';
import { getFormattedDate as getFormattedDateUtil } from '../../utils';
import { DateFormatFn } from '../../utils/date/types';

export type TUseDatePreferencesResult = {
  dateDisplayPreference?: string;
  inputFormat?: string;
  genericFormat?: string;
  getFormattedDate: DateFormatFn;
};

export const useDatePreferences = (): TUseDatePreferencesResult => {
  const { currentUser } = useCurrentUser();

  const dateDisplayPref = currentUser?.data?.dateDisplayPreference;

  // TODO: revisit as I think this is not a good access pattern
  const genericFormat = useMemo(() => getGenericDateFormat(dateDisplayPref), [
    dateDisplayPref,
  ]);

  // TODO: revisit as I think this is not a good access pattern
  const inputFormat = useMemo(() => getInputDateFormat(dateDisplayPref), [
    dateDisplayPref,
  ]);

  /**
   * Format a DateLike object using current user date preference
   */
  const getFormattedDate = React.useCallback((date, {
    ...rest
  } = {}) => (
    getFormattedDateUtil(date, { dateDisplayPref, ...rest})
  ), [dateDisplayPref]);

  return {
    dateDisplayPreference: dateDisplayPref,
    getFormattedDate,
    genericFormat,
    inputFormat,
  };
};

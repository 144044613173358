import { IRateTemplate, rateTemplateQueries } from '@esub-engineering/react-common-lib';
import { LaborClass, LaborClassStatus } from '../../../laborClass/types';
import { penniesToDollars } from '../../utils/penniesToDollars';
import { useCachedGraphQuery, UseCachedGraphQueryOptions } from '../useCachedGraphQuery';

type UseGetLaborClassesReturn = {
  getRateTemplate: IRateTemplate;
};

type UseGetLaborClassesInput = {
  templateId: string;
};

type LaborClassOptions = {
  label: string;
  value: string;
  original: LaborClass;
}[];

interface UseGetLaborClassesFromTemplateRateBaseProps<TData>
  extends UseCachedGraphQueryOptions<UseGetLaborClassesReturn, TData>,
    UseGetLaborClassesInput {}

type UseGetLaborClassesProps<TData> = Omit<
  UseGetLaborClassesFromTemplateRateBaseProps<TData>,
  'key' | 'gql'
>;

export const useGetLaborClassesFromTemplateRate = ({
  templateId,
  ...rest
}: UseGetLaborClassesProps<LaborClassOptions>) =>
  useCachedGraphQuery<UseGetLaborClassesReturn, LaborClassOptions, UseGetLaborClassesInput>({
    ...rest,
    enabled: Boolean(templateId),
    select: (data) => {
      const laborClasses = data?.getRateTemplate.laborClasses.items || [];
      // map labor classes into options, tag on the original in case we need it
      return laborClasses
        .filter(({ status }) => status === 'ACTIVE')
        .map((laborClass) => ({
          label: laborClass.laborClass.name,
          value: laborClass.laborClassId,
          original: {
            companyId: laborClass.laborClass.companyId,
            id: laborClass.laborClass.id,
            name: laborClass.laborClass.name,
            status: laborClass.laborClass.status as LaborClassStatus, // TODO : If we use the same LaborClassStatus in everything related to LaborClasses we can avoid this casting
          },
          rateInPennies: penniesToDollars(laborClass.rateInPennies),
        }));
    },
    key: [rateTemplateQueries.key, templateId],
    gql: rateTemplateQueries.getRateTemplateById,
    variables: {
      input: {
        id: templateId,
      },
    },
  });

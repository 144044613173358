import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    termsAndConditions: {
      textAlign: 'center',
      marginTop: '20px',
      fontSize: '12px',
    },
  })
);

export default useStyles;

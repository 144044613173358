import { ReduxActionPayload } from '../types';

export enum ProgressIndicatorEntities {
  DRIVE = 'DRIVE',
}

export type SupportedEntities = keyof typeof ProgressIndicatorEntities;

export type ProgressIndicatorState = {
  percentComplete: number;
  entity: SupportedEntities;
  status: string;
  customDescription?: string | undefined;
  open?: boolean;
};

export type ProgressIndicatorActionTypes =
  | 'SET_PROGRESS'
  | 'OPEN_PROGRESS'
  | 'CLOSE_PROGRESS'
  | 'RESET_PROGRESS';

export type ProgressIndicatorPayload = ReduxActionPayload<
  ProgressIndicatorActionTypes,
  ProgressIndicatorState | undefined
>;

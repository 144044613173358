import { useFlags as useLDFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import { getLDEnvironmentKey } from '../../config'
import { TUseFlagsApi } from './types'

export const useFlags = (): TUseFlagsApi => {
  const flags = useLDFlags()
  const flagsClient = useLDClient()
  const clientSDKKey = getLDEnvironmentKey(window.location.host)

  // api
  return {
    flags,
    client: flagsClient,
    environmentKey: clientSDKKey
  }
}

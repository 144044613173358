import React, { useState, useMemo, useCallback } from 'react';
import { MoreVert } from '@material-ui/icons';
import {
  PopOverMenu,
  Icon,
  TSortOptionsState,
  TBigCheckListState,
  TDateRangeState,
  PopoverColumnHeaderMenu,
  PopOverItemProps,
} from '@esub-engineering/react-component-library';
import difference from 'lodash/difference';
import {
  ISortedColumn,
  TListFilterMap,
  TDateFilterMap,
  TUseReactTableOptionsResult,
  TUseReactTableOptionsArgs,
} from './types';

const RowOptionsCell = React.memo(
  ({ id, menuItems }: { id: string; menuItems?: PopOverItemProps[] }) =>
    menuItems && menuItems.length ? (
      <PopOverMenu
        buttonProps={{
          id: `RowOptionsButton__${id}`,
        }}
        options={menuItems}
      >
        <Icon size="medium">
          <MoreVert />
        </Icon>
      </PopOverMenu>
    ) : null
);

/**
 * Add common utility to Virtualized (React) Tables
 * @todo move into component library in the near term
 */
export const useReactTableOptions = ({
  rowMenuItems,
  initialSort = { columnId: '', direction: undefined },
  initialListFilters = {},
  initialDateFilters = {},
  getRowId = (row) => row.id || undefined,
}: TUseReactTableOptionsArgs): TUseReactTableOptionsResult => {
  const [sort, setSort] = useState<ISortedColumn>(initialSort);
  const [listFilters, setListFilters] = useState<TListFilterMap>(initialListFilters);
  const [dateFilters, setDateFilters] = useState<TDateFilterMap>(initialDateFilters);

  // sort and direction
  const columnId = sort?.columnId;
  const direction = sort?.direction;

  // handle sort action
  const handleSort = useCallback(
    (state: TSortOptionsState, id: string) => {
      setSort({
        columnId: id,
        ...state,
      });
    },
    [setSort]
  );

  // handle list filter action
  const handleListFilter = useCallback(
    (state: TBigCheckListState, id: string) => {
      setListFilters((prev) => ({
        ...prev,
        [id]: {
          ...state,
        },
      }));
    },
    [setListFilters]
  );

  // handle date filter action
  const handleDateFilter = useCallback(
    (state: TDateRangeState, id: string) => {
      const INVALID_DATE_TEXT = 'Invalid Date';
      const isValidStartDate =
        state.startDate &&
        state.startDate.toString().toLowerCase() !== INVALID_DATE_TEXT.toLowerCase();
      const isValidEndDate =
        state.endDate && state.endDate.toString().toLowerCase() !== INVALID_DATE_TEXT.toLowerCase();

      if (
        (isValidStartDate && isValidEndDate) || // if both dates are valid, then set the date filter OR
        (state.startDate === undefined && state.endDate === undefined) // if both dates are undefined, then remove the date filter
      ) {
        setDateFilters((prev) => ({
          ...prev,
          [id]: {
            ...state,
          },
        }));
      }
    },
    [setDateFilters]
  );

  // combine new filter options
  const getNewFilterOptions = useCallback(
    (id: string, options: string[]) => {
      const currentFilters = listFilters[id]?.options || [];
      // options are purely additive so we can assume sameness needs no calculaiton
      if (options.length === currentFilters.length) return currentFilters;
      const currentOptions = currentFilters?.map((f) => f?.label);
      const newOptions = difference(options, currentOptions);
      const newFilters = newOptions.map((o) => ({
        label: o,
        checked: !!listFilters[id]?.checkAll,
      }));
      return [...currentFilters, ...newFilters];
    },
    [listFilters]
  );

  // are there list filters present in the table
  const hasListFilters = useMemo(
    () =>
      Object.keys(listFilters).some((id) =>
        listFilters[id].options.some((option: any) => option.checked)
      ),
    [listFilters]
  );

  // are there date filters present in the table
  const hasDateFilters = useMemo(
    () => Object.keys(dateFilters).some((id) => dateFilters[id].endDate || dateFilters[id].endDate),
    [dateFilters]
  );

  // extend row menu items based on row
  const getExtendedRowMenuItems = useCallback(
    (value: any, row: any) =>
      rowMenuItems?.map((item) => ({
        ...item,

        // pass in a function that adds props from row data
        ...(item.getProps ? item.getProps(row) : {}),

        // TODO: replace this with a single renderable method that takes only row as prop
        onClick: () => item.onClick({ id: getRowId(value), row: value }), // probably not necessary as we export the type too
      })),
    [getRowId, rowMenuItems]
  );

  // build row option column component
  const RowOptionsColumn = useMemo(
    () => ({
      id: 'RowOptions',
      width: '100px',
      Header: () => <div style={{ width: '64px' }} />,
      Cell: React.memo(({ value, row }: { value: string; row: any }) => (
        // NOTE: value is the values for the RENDERED cells...
        // TODO: transition code to use row itself as it is provided and optimized
        <RowOptionsCell id={getRowId(value)} menuItems={getExtendedRowMenuItems(value, row)} />
      )),
      accessor: (row: any) => row,
    }),
    [getExtendedRowMenuItems, getRowId]
  );

  // column builder
  const getColumn = useCallback(
    ({ id, title, listFilter, dateFilter, sort: sortProps, menuSize = null, headerAction }) => ({
      id,
      Header: React.memo(() =>
        Boolean(listFilter) || Boolean(dateFilter) || Boolean(sortProps) ? (
          <PopoverColumnHeaderMenu
            id={id}
            title={title}
            on={
              Boolean(listFilters[id]?.options.some((option: any) => option.checked)) ||
              Boolean(dateFilters[id]?.endDate || dateFilters[id]?.startDate) ||
              columnId === id
            }
            columnOptionsMenuProps={{
              listFilter: listFilter
                ? {
                    ...listFilter,
                    checkAll: listFilters[id]?.checkAll ? listFilters[id].checkAll : undefined,
                    options: getNewFilterOptions(id, listFilter.options),
                    onChange: (event: any, state: any) => handleListFilter(state, id),
                    onClick: (event: any, state: any) => handleListFilter(state, id),
                  }
                : undefined,

              dateFilter: dateFilter
                ? {
                    ...dateFilter,
                    startDate: dateFilters[id] ? dateFilters[id].startDate : undefined,
                    endDate: dateFilters[id] ? dateFilters[id].endDate : undefined,
                    onChange: (state: any) => handleDateFilter(state, id),
                  }
                : undefined,

              sort: sortProps
                ? {
                    ...sortProps,
                    direction: columnId === id ? direction : undefined,
                    onChange: (event: any, state: any) => handleSort(state, id),
                  }
                : undefined,

              menuSize,
            }}
          />
        ) : (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>
            {headerAction !== null ? (
              <>
                {title}
                {headerAction}
              </>
            ) : (
              { title }
            )}
          </>
        )
      ),
    }),
    [
      columnId,
      handleSort,
      dateFilters,
      direction,
      handleDateFilter,
      handleListFilter,
      listFilters,
      getNewFilterOptions,
    ]
  );

  return {
    sort,
    RowOptionsColumn,
    getColumn,
    hasDateFilters,
    hasListFilters,
    listFilters,
    dateFilters,
  };
};

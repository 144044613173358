import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from '../../redux/currentUser/actions';
import { TCurrentUser } from '../../redux/currentUser';

export type TUseCurrentUserResult = TCurrentUser & {
  currentUser: TCurrentUser | null;
  setCurrentUser: (payload: Partial<TCurrentUser>) => void;
};

export const useCurrentUser = (): TUseCurrentUserResult => {
  const dispatch = useDispatch();

  const currentUserStore = useSelector<{ currentUser: TCurrentUser }, TCurrentUser>(
    (state) => state.currentUser
  );

  const setCurrentUser = useCallback(
    (payload: Partial<TCurrentUser>) => dispatch(setUser(payload)),
    [dispatch]
  );

  return {
    ...currentUserStore, // more useful to have the data in this format
    currentUser: currentUserStore, // keeping this for compatibility
    setCurrentUser,
  };
};

type TEnvironment = 'production' | 'demo' | 'dev';

export const ESUB_LD_USER_KEY = 'esub-test-user';
export const ESUB_LD_PREPROD_EMAIL = 'qa_user@simulator.amazonses.com';
export const ESUB_LD_PREPROD_FIRSTNAME = 'qa';
export const ESUB_LD_PREPROD_LASTNAME = 'user';
export const ESUB_LD_PREPROD_ORGID = 'PREPROD_ORGID';
export const ESUB_LD_PREPROD_COMPANYID = 'PREPROD_COMPANYID';

// env keys for web project
export const environmentSDKKeys = Object.freeze({
  production: '62b6253fc1b54d15c8f942b7',
  demo: '62b626140cd0601596228345',
  dev: '62b626500fd5ab151ff40650',
});

export const getLDEnvironmentKey = (host: string) => {
  let environment = 'dev'; // default to dev

  if (host === 'esubcloud.com' || host === 'www.esubcloud.com') {
    environment = 'production';
  }

  if (host === 'demo.esubcloud.com' || host === 'www.demo.esubcloud.com') {
    environment = 'demo';
  }

  return environmentSDKKeys[environment as TEnvironment];
};

import { IAttachmentLandingZoneState } from '@esub-engineering/react-component-library';
import { IDailyReport } from '../types';
import { ITimecardsSummary, IUserTimeEntry, ITimeEntry } from '../../timeCardManagement/types';
import { ICreateTimeCardInput } from '../../timeCardManagement/components/MultiTimeEntryForm/types';
import {
  SET_DAILY_REPORT,
  RESET_DAILY_REPORT,
  SET_PENDING_DAILY_REPORT_ATTACHMENTS,
  RESET_PENDING_DAILY_REPORT_ATTACHMENTS,
  SET_CURRENT_TIME_ENTRIES,
  RESET_CURRENT_TIME_ENTRIES,
  SET_TIME_ENTRIES_PENDING_CHANGES,
  RESET_TIME_ENTRIES_PENDING_CHANGES,
  SET_TIME_ENTRIES_TO_DELETE,
  RESET_TIME_ENTRIES_TO_DELETE,
  SET_TIME_ENTRIES_TO_CREATE,
  RESET_TIME_ENTRIES_TO_CREATE,
  DELETE_TIME_ENTRIES_TO_CREATE,
  ARE_NEW_TIME_ENTRIES_ADDED,
} from './actions';

export type TDailyReportActionTypes =
  | typeof SET_DAILY_REPORT
  | typeof RESET_DAILY_REPORT
  | typeof SET_PENDING_DAILY_REPORT_ATTACHMENTS
  | typeof RESET_PENDING_DAILY_REPORT_ATTACHMENTS
  | typeof SET_CURRENT_TIME_ENTRIES
  | typeof RESET_CURRENT_TIME_ENTRIES
  | typeof SET_TIME_ENTRIES_PENDING_CHANGES
  | typeof RESET_TIME_ENTRIES_PENDING_CHANGES
  | typeof SET_TIME_ENTRIES_TO_DELETE
  | typeof RESET_TIME_ENTRIES_TO_DELETE
  | typeof SET_TIME_ENTRIES_TO_CREATE
  | typeof RESET_TIME_ENTRIES_TO_CREATE
  | typeof DELETE_TIME_ENTRIES_TO_CREATE
  | typeof ARE_NEW_TIME_ENTRIES_ADDED;

export type TCrewTimeManagementState = {
  currentTimeEntries: ITimeEntry[];
  crewTimePendingChanges: IUserTimeEntry[];
  crewTimeEntriesToDelete: string[];
  crewTimeEntriesToCreate: ICreateTimeCardInput[];
  areNewTimeEntriesAdded: boolean;
  updatedCrewTime: Partial<ITimecardsSummary>;
};

export type TDailyReportState = TCrewTimeManagementState & {
  pendingDailyReport: Partial<IDailyReport>;
  pendingAttachments: IAttachmentLandingZoneState<any, any>;
};

const initialState: TDailyReportState = {
  pendingDailyReport: {},
  pendingAttachments: {
    files: [],
    media: [],
  },
  currentTimeEntries: [],
  crewTimePendingChanges: [],
  crewTimeEntriesToDelete: [],
  crewTimeEntriesToCreate: [],
  areNewTimeEntriesAdded: false,
  updatedCrewTime: {},
};

export default (
  // eslint-disable-next-line
  state = initialState,
  { type, payload }: { type: TDailyReportActionTypes; payload: any }
) => {
  if (type === SET_DAILY_REPORT) {
    return {
      ...state,
      pendingDailyReport: payload,
    };
  }

  if (type === RESET_DAILY_REPORT) {
    return {
      ...state,
      pendingDailyReport: {},
    };
  }

  if (type === SET_PENDING_DAILY_REPORT_ATTACHMENTS) {
    return {
      ...state,
      pendingAttachments: payload,
    };
  }

  if (type === RESET_PENDING_DAILY_REPORT_ATTACHMENTS) {
    return {
      ...state,
      pendingAttachments: {
        files: [],
        media: [],
      },
    };
  }

  if (type === SET_CURRENT_TIME_ENTRIES) {
    return {
      ...state,
      currentTimeEntries: payload,
    };
  }

  if (type === RESET_CURRENT_TIME_ENTRIES) {
    return {
      ...state,
      currentTimeEntries: [],
    };
  }

  if (type === SET_TIME_ENTRIES_PENDING_CHANGES) {
    const filteredEntries = state.crewTimePendingChanges.filter((entry) => entry.id !== payload.id);

    return {
      ...state,
      crewTimePendingChanges: [...filteredEntries, payload],
    };
  }

  if (type === RESET_TIME_ENTRIES_PENDING_CHANGES) {
    return {
      ...state,
      crewTimePendingChanges: [],
    };
  }

  if (type === SET_TIME_ENTRIES_TO_DELETE) {
    return {
      ...state,
      crewTimeEntriesToDelete: state.crewTimeEntriesToDelete.includes(payload)
        ? state.crewTimeEntriesToDelete
        : [...state.crewTimeEntriesToDelete, payload],
    };
  }

  if (type === RESET_TIME_ENTRIES_TO_DELETE) {
    return {
      ...state,
      crewTimeEntriesToDelete: [],
    };
  }

  if (type === SET_TIME_ENTRIES_TO_CREATE) {
    return {
      ...state,
      crewTimeEntriesToCreate: [...state.crewTimeEntriesToCreate, ...payload],
    };
  }

  if (type === DELETE_TIME_ENTRIES_TO_CREATE) {
    return {
      ...state,
      crewTimePendingChanges: state.crewTimePendingChanges?.filter(
        (timecard: IUserTimeEntry) => timecard.id !== payload
      ),
      crewTimeEntriesToCreate: state.crewTimeEntriesToCreate?.filter(
        (timecard: ICreateTimeCardInput) => timecard.id !== payload
      ),
    };
  }

  if (type === RESET_TIME_ENTRIES_TO_CREATE) {
    return {
      ...state,
      crewTimeEntriesToCreate: [],
    };
  }

  if (type === ARE_NEW_TIME_ENTRIES_ADDED) {
    return {
      ...state,
      areNewTimeEntriesAdded: payload,
    };
  }

  return state;
};

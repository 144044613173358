import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles(() =>
  createStyles({
    box: {
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      margin: `8px 0`,
    },
    title: {
      color: 'primary',
      fontWeight: 'bold',
      margin: '8px',
    },
    content: {
      lineHeight: '20px',
      wordBreak: 'break-word',
    },
  })
);

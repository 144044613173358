import { Organization } from '@esub-engineering/platform-types';
import { SET_ORG_INFO } from './actions';

export const initialState: Partial<Organization> = {
  modules: [],
  integrationsSettings: {
    accountingIntegration: null,
    isConnected: false,
  },
};

type OrgAction = {
  type: typeof SET_ORG_INFO;
  payload: Partial<Organization>;
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export const reducer = (state = initialState, { type, payload }: OrgAction) => {
  switch (type) {
    case SET_ORG_INFO:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};

import { useReactTableOptions } from '../useReactTableOptions';
import { TUseReactTableOptionsArgs } from '../useReactTableOptions/types';

export type TStickyTableProps = TUseReactTableOptionsArgs & {
  stickySpace: string;
};

export const useStickyTable = ({
  stickySpace,
  initialSort = { columnId: '', direction: undefined },
  initialListFilters = {},
  initialDateFilters = {},
  ...rest
}: TStickyTableProps) => {
  const { sort, dateFilters, listFilters, ...additionalResults } = useReactTableOptions({
    ...rest,
    initialSort,
    initialListFilters,
    initialDateFilters,
  });

  // initial filters are now store in localStorage instead of redux.

  return { sort, dateFilters, listFilters, ...additionalResults };
};

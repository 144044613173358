import React, { useMemo, useState } from 'react';
import { ControlledContactTextField } from '@esub-engineering/common-containers';
import { IContactSelectorProps } from './type';
import { useSearchContactsV2 } from '../../hooks/useSearchContact';

export const ContactSelector = React.memo(
  ({
    control,
    name,
    label,
    id,
    isEmail = false,
    multiple = true,
    ...rest
  }: IContactSelectorProps) => {
    const [searchCriteria, setSearchCriteria] = useState<string>('');

    const filters = useMemo(
      () => ({
        filter: {
          status: { eq: 'AVAILABLE' },
        },
        sortBy: 'NAME',
        direction: 'ASC',
      }),
      []
    );

    const { data: contactsFound, isLoading: isLoadingContact } = useSearchContactsV2({
      variables: { term: searchCriteria, ...filters },
    });

    const incomingOptions = useMemo(() => contactsFound?.pages.flatMap((d) => d.items) || [], [
      contactsFound?.pages,
    ]);

    const filteredOptions = useMemo(
      () =>
        incomingOptions
          ?.filter((d) => d.document.email !== null)
          .map((item: any) => {
            const { name: dName, email: dEmail, id: dId } = item.document;
            return {
              id: dId,
              name: dName,
              email: dEmail,
            };
          }) || [],
      [incomingOptions]
    );

    return (
      <ControlledContactTextField
        fetchingOptions={isLoadingContact}
        name={name}
        control={control}
        label={label}
        id={id}
        fullWidth
        setSearchCriteria={setSearchCriteria}
        options={filteredOptions}
        isEmail={isEmail}
        multiple={multiple}
        {...rest}
      />
    );
  }
);

import React from 'react';
import { Provider } from 'react-redux';
import { themes } from '@esub-engineering/react-component-library';
import { ThemeProvider } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { QueryClientProvider } from 'react-query';
import { GraphQLClientProvider } from '@esub-engineering/common-containers';
import { queryClient } from '@esub-engineering/react-common-lib';
import { ReactQueryDevtools } from 'react-query/devtools';
import { AnalyticsGraphQLClientProvider } from '../common/providers';
import configureStore from '../redux/configureStore';
import { Routes } from '../routes';
import { useDatadog } from '../common/hooks';

const store = configureStore();

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

const App = () => {
  const { DataDog } = useDatadog();
  return (
    <>
      <DataDog />
      <CssBaseline />
      <Routes />
    </>
  );
};

const Providers = () => {
  const { REACT_APP_DEFAULT_ENDPOINT } = process.env;

  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <GraphQLClientProvider defaultEndpoint={REACT_APP_DEFAULT_ENDPOINT}>
          <AnalyticsGraphQLClientProvider>
            <ThemeProvider theme={themes.main}>
              <App />
            </ThemeProvider>
          </AnalyticsGraphQLClientProvider>
        </GraphQLClientProvider>
      </QueryClientProvider>
    </Provider>
  );
};

export default Providers;

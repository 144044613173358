import { ReduxAction } from '../types';
import { ProgressIndicatorPayload, ProgressIndicatorState } from './types';

export const SET_PROGRESS = 'SET_PROGRESS';
export const OPEN_PROGRESS = 'OPEN_PROGRESS';
export const CLOSE_PROGRESS = 'CLOSE_PROGRESS';
export const RESET_PROGRESS = 'RESET_PROGRESS';

export const setProgress: ReduxAction<ProgressIndicatorState, ProgressIndicatorPayload> = (
  payload
) => ({
  type: SET_PROGRESS,
  payload,
});

export const openProgress: ReduxAction<ProgressIndicatorState, ProgressIndicatorPayload> = (
  payload
) => ({
  type: OPEN_PROGRESS,
  payload,
});

export const closeProgress: ReduxAction<ProgressIndicatorState, ProgressIndicatorPayload> = () => ({
  type: CLOSE_PROGRESS,
});

export const resetProgress: ReduxAction<ProgressIndicatorState, ProgressIndicatorPayload> = () => ({
  type: RESET_PROGRESS,
});

import { useCallback, useMemo, useState } from 'react';
import { PopOverItemProps } from '@esub-engineering/react-component-library';
import { get } from 'lodash';

type PopOverItemPropsCustom = Omit<PopOverItemProps, 'disabled'> & {
  disabled?: (row: any) => boolean | boolean;
};

type UseCardListProps<Data extends { [key: string]: any }> = {
  data: Data[];
  selectionEnabled?: boolean;
  menuOptions?: PopOverItemPropsCustom[];
  transform: (d: Data) => {};
  idKey: string;
  onClick?: (args: { id: string; item?: Data }) => void;
  customProps?: any;
};

export type UseCardListReturn<CardProps extends any> = {
  listData: CardProps[];
  isIndeterminate: boolean;
  handleSelectAll: (event: React.ChangeEvent<HTMLInputElement>) => void;
  selected: {
    checkAll: boolean;
    options: string[];
  };
};

export const useCardList = <Data extends { [key: string]: any }, CardProps extends any>({
  data,
  transform,
  selectionEnabled,
  menuOptions,
  idKey,
  onClick,
  customProps = {},
}: UseCardListProps<Data>): UseCardListReturn<CardProps> => {
  const [selected, setSelected] = useState<{ checkAll: boolean; options: string[] }>({
    checkAll: false,
    options: [],
  });

  const isIndeterminate = useMemo(
    () =>
      selected.checkAll === true &&
      selected.options?.length < data.length &&
      selected.options.length > 0,
    [selected.checkAll, selected.options.length, data.length]
  );

  const handleSelectAll = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const checkOptions = data.map((item) => get(item, idKey));

      const update = event.target.checked
        ? {
            checkAll: true,
            options: checkOptions,
          }
        : {
            checkAll: false,
            options: [],
          };
      setSelected(update);
    },
    [data, idKey]
  );

  const handleSelection = useCallback(
    ({
      event,
      id,
    }: {
      event: React.ChangeEvent<HTMLInputElement>;
      id: string;
      index: string | number;
    }) => {
      setSelected((s) => ({
        ...s,
        options: event.target.checked ? [...s.options, id] : s.options.filter((o) => o !== id),
      }));
    },
    []
  );

  // extend row menu items based on row
  const getExtendedCardMenuItems = useCallback(
    (id: string, row: any) =>
      menuOptions
        ?.map((item) => ({
          ...item,
          disabled: typeof item.disabled === 'function' ? item.disabled(row) : item.disabled,
          onClick: () => (item.onClick ? item.onClick({ id, item: row }) : undefined),
        }))
        .filter((item) => !item.disabled),
    [menuOptions]
  );

  const listData = useMemo(
    () =>
      data.map(
        (item) =>
          ({
            ...customProps,
            ...(transform ? transform(item) : item),
            options: getExtendedCardMenuItems(get(item, idKey), item),
            handleSelection: selectionEnabled ? handleSelection : undefined,
            selected: Boolean(selected.options.find((option) => option === get(item, idKey))),
            onClick: () => onClick && onClick({ id: get(item, idKey), item }),
          } as CardProps)
      ),
    [
      data,
      transform,
      getExtendedCardMenuItems,
      idKey,
      selectionEnabled,
      handleSelection,
      selected.options,
      onClick,
      customProps,
    ]
  );

  return {
    listData,
    isIndeterminate,
    handleSelectAll,
    selected,
  };
};

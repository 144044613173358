import { materialColors } from '@esub-engineering/react-component-library/dist/src/themes';
import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: '5px',
      width: '100%',
      borderRadius: '4px',
    },
    colorPrimary: {
      backgroundColor: materialColors.grey[200],
    },
    bar: {
      borderRadius: '4px',
      backgroundColor: materialColors.blue[500],
    },
  })
);

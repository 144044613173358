import { useMemo, useState, useCallback } from 'react';
import { useDownload } from '@esub-engineering/react-component-library';
import { getReportFormattedDate } from '@esub-engineering/react-common-lib';
import _get from 'lodash/get';
import { useToast } from '../useToast';
import { UseGeneratePdfArgs, UseGeneratePdfResult } from './types';

/**
 * Download PDFs by providing standard input and a mutation that provides a url
 */
export const useGeneratePdf = <MutationInput extends {}, MutationResult extends {}>({
  mutation,
  attrs,
}: UseGeneratePdfArgs<MutationInput, MutationResult>): UseGeneratePdfResult<MutationInput> => {
  const { download } = useDownload();

  const { openErrorToast } = useToast();

  // const {
  //   mutateAsync: createFieldNotePdfMutation,
  //   isLoading: isCreateFieldNotePdfMutationLoading,
  // } = useFieldNotePdf();

  const [downloading, setDownloading] = useState<boolean>(false);
  const [err, setErr] = useState<unknown | undefined>();

  const loading = useMemo(() => downloading, [downloading]);
  const error = useMemo(() => err, [err]);

  const downloadPdf = useCallback(
    async ({ input }: { input: MutationInput }) => {
      // set general downloading state here
      setDownloading(true);

      const now = new Date();

      // get presigned link
      try {
        const url = await mutation({
          input: {
            // default some date params for print results in case they are not set by the inputs
            createdAt: now.toISOString(),
            displayDate: getReportFormattedDate(now),
            ...input,
          },
        });

        // trigger download
        download({
          href: _get(url, attrs.href),
          filename: _get(url, attrs.download),
        });
      } catch (ex) {
        openErrorToast('Unable to download pdf.');
        setErr(ex);
      } finally {
        setDownloading(false);
      }
    },
    [download, mutation, openErrorToast, attrs.href, attrs.download]
  );

  return { downloadPdf, loading, error };
};

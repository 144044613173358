import { paths as dailyReportsPaths } from '../DailyReports/paths';
import { paths as fieldNotesPaths } from '../FieldNotes/paths';
import { paths as projectInfoPaths } from '../ProjectInfo/paths';
import { paths as projectListPaths } from '../ProjectList/paths';
import { paths as projectSummaryPaths } from '../ProjectSummary/paths';
import { paths as RFIPaths } from '../RFIs/paths';
import { paths as mailboxPaths } from '../Mailbox/paths';
import { paths as snapshotsPaths } from '../Snapshots/paths';
import { paths as sheetPaths } from '../Sheets/paths';

export const paths = [
  ...dailyReportsPaths,
  ...fieldNotesPaths,
  ...projectInfoPaths,
  ...projectListPaths,
  ...projectSummaryPaths,
  ...RFIPaths,
  ...mailboxPaths,
  ...sheetPaths,
  ...snapshotsPaths,
];
